export const ConnectOneSignal = (account, user) => {
  if (!process.env.REACT_APP_ENV) return;

  window.OneSignal = window.OneSignal || [];
  const { OneSignal } = window;
  const myCustomUniqueUserId = user.id;
  OneSignal.push(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    });
    OneSignal.setExternalUserId(myCustomUniqueUserId);
    const { id: userId, companyId, privileges } = user;
    const { whiteLabelId } = account;
    OneSignal.sendTags({
      userId,
      companyId,
      whiteLabelId,
      labs: {},
      privileges,
    });
  });
};

export const removePreLoader = () => {
  const preloader = document.querySelector('#clgo-preloader');
  if (preloader) {
    const path = document.querySelector('#clgo-preloader path');
    if (path) path.style.display = 'none';
    const svg = document.querySelector('#clgo-preloader svg');
    if (svg) {
      svg.style.transform = 'scale(15)';
      svg.addEventListener('transitionend', () => {
        preloader.style.opacity = '0';
      });
    }
  }
};
