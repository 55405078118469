import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const createMarkup = (html) => ({ __html: html });

const RelevantMessageStyles = styled.p`
  padding-bottom: .8rem;
`;

export const RelevantMessage = ({
  content,
}) => (
  <RelevantMessageStyles>
    &quot;
    <span dangerouslySetInnerHTML={createMarkup(content)} />
    &quot;
  </RelevantMessageStyles>
);

RelevantMessage.propTypes = {
  content: PropTypes.string,
};

export default RelevantMessage;
