import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SearchSelect, DatePickerInput } from 'cliengo-ui';
import './filter.css';

const FiltersHistory = ({
  convers,
  sites,
  currentSite,
  titleWindow,
  onChangeSite,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const changeShowFilter = () => setShowFilters(!showFilters);

  return (
    <Container>
      {/* TITULO DE LA VENTANA */}
      <TitleContainer>
        <Title>{titleWindow}</Title>
        <FilterIcon
          show={showFilters || convers.length === 0}
          className="icon icon-filter1"
          onClick={changeShowFilter}
        />
      </TitleContainer>

      {/* SELECTOR DE SITIO */}
      <Filers show={showFilters}>
        <SearchSelect
          options={sites}
          placeholder="Selecciona el sitio web"
          value={currentSite}
          onChange={onChangeSite}
          styleContainer={{ margin: '16px auto', width: '90%' }}
        />

        {/*  DATE PICKER DE FECHAS */}
        <DatePickerContainer>
          {/* Date From */}
          <DatePickerInput
            callback={setDateFrom}
            labelText="Desde"
            id="dateFrom-history"
            value={dateFrom}
            placeholderText="dd/mm/aaaa"
            // error="errorrr"
            // maxDate={new Date()}
            stylesForIcon={{ borderLeft: 'none' }}
            wrapperClass="datePickerInput"
            calendarClassName="calendar-shadow"
          />

          {/* Date To */}
          <DatePickerInput
            stylesForIcon={{ borderLeft: 'none' }}
            callback={setDateTo}
            labelText="Hasta"
            placeholderText="dd/mm/aaaa"
            id="dateTo-history"
            wrapperClass="datePickerInput"
            value={dateTo}
            calendarClassName="calendar-shadow"
            // error={errors.toDate || error}
          />
        </DatePickerContainer>
        <DropIcon
          show={showFilters}
          className="icon icon-angle-up"
          onClick={changeShowFilter}
        />
      </Filers>

      {/* TODO implementar el resto de los filtros */}
      <div>el resto de los filtros</div>
      <div className="row justify-content">
        <button
          className="buttonFilter"
          type="button"
          onClick={() => console.info(1)}
        >
          Aplicar filtros
        </button>
      </div>
    </Container>
  );
};

const DropIcon = styled.span`
  background: rgba(255, 255, 255, 0.7);
  padding: 4px;
  border: 1px solid #bbbbbb;
  color: #696868;
  border-radius: 50%;
  right: 20px;
  transition: opacity 300ms;
  pointer-events: ${(props) => (!props.show ? 'none' : 'auto')};
  font-size: 32px;
  width: 26px;
  height: 26px;
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  &:before {
    margin-top: -4px;
  }
  @media (max-width: 764px) {
    display: flex;
  }
`;

const FilterIcon = styled.span`
  background: rgba(255, 255, 255, 0.7);
  padding: 4px;
  border: 1px solid #bbbbbb;
  color: #696868;
  font-size: 18px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  transition: opacity 300ms;
  opacity: ${(props) => (props.show ? '0' : '1')};
  display: none;
  pointer-events: ${(props) => (props.show ? 'none' : 'auto')};
  @media (max-width: 764px) {
    display: block;
  }
`;

const Filers = styled.div`
  transition: transform 300ms ease;
  transform-origin: top;
  transform-style: flat;
  display: ${(props) => (props.empty ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: scaleY(1);
  z-index: 2;
  @media (max-width: 756px) {
    transform: ${(props) => (props.show ? 'scaleY(1)' : 'scaleY(0)')};
  }
`;

const Container = styled.div`
  font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: #f2f2f2;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-width: calc(${(props) => (props.width ? props.width : 'auto')} - 20px);
  box-shadow: inset 0 2px 6px rgb(0 0 0 / 16%);
  @media (max-width: 480px) {
    width: 100%;
    max-width: 100%;
  }
`;

const TitleContainer = styled.div`
  background-color: #f2f2f2;
  padding: 18px;
  position: relative;
  border-bottom: solid 1px #00000014;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.105);
  @media (max-width: 756px) {
    justify-content: flex-start;
  }
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  span {
    position: absolute;
    top: 17px;
    right: 10px;
    font-size: 14px;
    @media (max-width: 480px) {
      right: auto;
      left: 100px;
    }
  }
  @media (max-width: 756px) {
    margin-right: 12px;
  }
`;

const DatePickerContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: 0 auto 12px auto;
  #dateFrom-history,
  #dateTo-history {
    width: calc(100% - 14px);
  }
  .datePickerInput {
    width: 48%;
  }
`;

FiltersHistory.propTypes = {
  convers: PropTypes.arrayOf(PropTypes.any),
  titleWindow: PropTypes.string,
};

FiltersHistory.defaultProps = {
  titleWindow: 'Historial de chats',
  convers: [],
};

export default FiltersHistory;
