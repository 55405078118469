import styled from 'styled-components';
import { Colors } from '../../../utils';

const ChatContainer = styled.div`
  font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ChatWindow = styled.div`
  background: #f7f7f7;
  border-left: 1px solid #ddd;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const ChatboxBody = styled.div`
  height: calc(100% - 50px); //esos 50px son el padding x 2
  padding:25px;
  overflow-y: auto;

 /* Esto es para que el ultimo mensaje no tape el powered by Cliengo */
  >div>div:last-child {
      margin-bottom: 35px;
    }

`;
// height: ${props => props.height ? props.height: 'auto'};
const MessagesWindow = styled.div`
    width: 100%;
    transition: all .5s;
    height: calc(100% - 119px ); //esos 119 es la suma del heigh del header y el footer

    ::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 480px) {
      height:calc(${(props) => props.height} - 60px);
    }

    @media (max-width: 768px) {
      height: 75%;
    }

    ${({ isOpenEmoji }) => isOpenEmoji && `
      height: calc(100% - 119px - 200px);
    `}

    ${({ finishedConversation, activeFinishedMessage }) => finishedConversation && activeFinishedMessage && `
    height: calc(100% - 119px - 45px)
    `}
  `;

const ConversationMeta = styled.div`
  color: ${(props) => props.color || Colors.cliengoMain};
  width: 100%;
  position: relative;
  margin: 10px 0;
  marginTop: 0;
  text-align: center;
  font-size: 12px;
  p{
    font-size: 12px;
    margin: 0;
    display: inline;
  }
  &:before{ left: 0; }
  &:after{ right: 0; }
  &:before,
  &:after{
    content: '';
    position: absolute;
    width: calc(50% - 95px);
    bottom: 50%;
    height: 100%;
    border-bottom: 1px solid ${(props) => props.color || Colors.cliengoMain};;
  }
`;

const Footer = styled.div`
  text-align: center;
  color: rgb(170, 170, 170) !important;
  width: 100%;
  .chat-input-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #fff;
    align-items: center;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  input {
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    box-sizing: border-box;
    color: #777777;
    line-height: 27px;
    padding: 12px ${(props) => (props.isAttachment ? '80px' : '72px')} 13px 12px;
    width: 100%;
    height: 44px;
    transition: .5s;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    margin-bottom: 0;
    &:focus {
      border-color: ${Colors.cliengoMain} !important;
      outline: none!important;
    }
    &::placeholder {
      color: #D1D1D1;
    }
  }
  .box-message-btn {
    position: absolute;
    right: 1px;
    display: flex;
    margin-left: -30px;
    @media (max-width: 580px) {
      margin-left: -41px;
    }
    .send-message-button {
      position:relative;
      z-index:2
      box-sizing: border-box;
      padding-top: 11px;
      padding-right: 15px;
      cursor: pointer;
      display: block;
      font-size: 16px;
      height: 30px;
      @media (max-width: 580px) {
        background: #7D60F5;
        text-align: center;
        padding: 7px;
        border-radius: 4px;
        height: 27px;
        width: 30px;
        padding-top: 10px;

      }
    }
    .send-message-button:before {
      color: #7D60F5;
      @media (max-width: 580px) {
        color: #ffffff;
        vertical-align: middle;
      }
    }

    .attachment-message-button {
      position:relative;
      z-index:2
      box-sizing: border-box;
      padding-top: 0px;
      padding-right: 11px;
      border-right: 1px solid #ddd;
      margin-right: 11px;
      cursor: pointer;
      display: block;
      font-size: 15px;
      color: #515151;
      line-height: 1.5;
      height: 21px;
      margin-top: 9px;
      @media (max-width: 580px) {
        display: none;

      }
    }

    .emojis-button-insert {
      position:relative;
      z-index:2
      box-sizing: border-box;
      padding-top: 11px;
      padding-right: 15px;
      cursor: pointer;
      display: block;
      font-size: 16px;
      height: 30px;
      @media (max-width: 580px) {
        text-align: center;
        padding: 7px;
        border-radius: 4px;
        height: 27px;
        width: 30px;
        padding-top: 10px;

      }
    }

  }

`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  background-color: #fff;
  padding: 10px;
`;


/* Chat header styles */


const TopBar = styled.div`
  font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
  width: 100%;
  height: 55px;
  background-color: #FFFFFF;
  padding: 12px 20px 10px 20px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 2px #00000029;
  // position: absolute;
  // top:0;
  // z-index: 1;
  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .topbar-title-container {
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    .topbar-avatar {
      width: 35px;
      height: 35px;
      background-size: cover;
      border-radius: 50%;
      clear: both;
      float: left;
      background-position: center center;
      color: #ffffff;
      margin-right: 7px;
      margin-top: 1px;
      display: inline-block;
    }
    .topbar-text {
      display: inline-block;
      .topbar-title {
        color: #515151;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: capitalize;
      }
      .topbar-subtitle {
        color: ${Colors.colorOfLine};
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
`;

const CircleStatus = styled.span`
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
`;

const IconArrowChat = styled.span`
  display:none;
  i {
    color: ${Colors.colorFontGeneral};
    margin-right: 20px;
    font-size: 16px;
  }
  @media (max-width: 580px) {
    display: inline-block;
    vertical-align: top;
    margin-top: 7px;
  }


`;

const TopbarAction = styled.div`
  display: flex;
  position: relative;
`;

const DropdownMenu = styled.ul`
  overflow-y: auto;
  max-height: 200px;
  min-width: 180px;
  display: ${(props) => (props.fadeInDown ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  right: -5px;
  z-index: 1000;
  padding: 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  background-clip: padding-box;
  opacity: 0;
  transition: opacity 500ms ease-out .5s;
  &.fadeInDown {
    opacity: 1;
    top: 100%;
    right: -5px;
    margin-top: 4px;
  }
  @media (min-width: 580px) {
    &.fadeInDown {
      display: none
    }

  }
`;

const ElementMenu = styled.li`
  list-style: none;
  font-size: 14px;
  div {
    border: 0;
    i {
      margin-right: 11px;
    }
  }
  @media (max-width: 580px) {
    div {
      display:block;
      margin-left: 0;
      padding: 17px 18px;
      text-align: left;
      letter-spacing: 0px;
    }
    div:first-child {
      border-bottom: 1px solid #ddd;
      border-radius: 0;
      padding-left: 16px;
      i {
        margin-right: 9px;
      }
    }
  }
`;

const TopbarButton = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #515151;
  cursor: pointer;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DEDEDE;
  border-radius: 18px;
  padding: 7px 17px;
  margin-left: 10px;
  margin-right: 5px;
  span {
    font-size: 11px;
    line-height: 0;
  }
  i {
    margin-right: 8px;
  }
  @media (max-width: 580px) {
    display: none
  }
  display: ${(p) => (p.hide ? 'none' : 'auto')};
`;

const ContactDetail = styled.div`
  text-align: center;
  display: ${(props) => (props.hiddeIcon ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 48px;
  cursor: pointer;
  i {
    font-size: 20px;
  }
  @media (max-width: 580px) {
  }

`;

const MobileIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 48px;
  i {
    display:block;
    margin-bottom: 2px;
    font-size: 5px;
    color: #515151;
  }
  @media (min-width: 580px) {
    display:none;
  }
`;

const ModalContent = styled.div`
  font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #00000026;
  border-radius: 4px;
  overflow:hidden;
  width: 380px;
  .endChat:disabled{
    background-color: rgba(235,41,99,0.5)!important;
  }
`;

const ModalHeader = styled.div`
  background: #fff;
  justify-content: flex-end;
  display: flex;
  transition: all 1s;
  padding: 14px 15px 0 23px;
  i {
    color: #B3B3B3;
    font-size: 20px;
    cursor:pointer;
  }
`;

const ModalBody = styled.div`
  background: #fff;
  display: block;
  transition: all 1s;
  padding: 0px 23px 27px 23px;
  text-align: right;
  h2 {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #515151;
    margin-top: 0;
    margin-bottom: 14px;
  }
  h3 {
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    letter-spacing: 0px;
    color: #515151;
    margin: 0;
  }
  p {
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px;
    color: #515151;
    margin-top: 8px;
    margin-bottom: 30px;
    i {
      color: #B1346E;
      margin-right: 8px;
    }
  }
  button {
    box-shadow: none;
    margin-left: 14px;
    min-width: 90px;
    &:focus{
      outline: none;
    }
  }
`;

export {
  ChatContainer,
  ChatWindow,
  ChatboxBody,
  MessagesWindow,
  ConversationMeta,
  Footer,
  FormContainer,
  TopBar,
  CircleStatus,
  IconArrowChat,
  TopbarAction,
  DropdownMenu,
  ElementMenu,
  TopbarButton,
  ContactDetail,
  MobileIcon,
  ModalContent,
  ModalHeader,
  ModalBody,
}
