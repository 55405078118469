import styled from 'styled-components';

const SkeletonPulse = styled.div`
  display: inline-block;
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  background: linear-gradient(-90deg, #F0F0F0 0%, #e2e2e2 50%, #F0F0F0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -135% 0;
    }
  }
`;

export default SkeletonPulse;
