import React from 'react';
import styleAccordion from './accordion-css';

const Accordion = ({
  title, isOpen, callback, ...props
}) => (
  <div className="row is-flex-direction-column">
    <div style={styleAccordion.container}>
      <div style={styleAccordion.header} onClick={callback}>
        <p style={styleAccordion.label}>
          {title}
          <i className="icon-expand-more" style={styleAccordion.icon} />
        </p>
      </div>
      {isOpen
        && (
        <div>
          {props.children}
        </div>
        )}
    </div>
  </div>
);

export default Accordion;
