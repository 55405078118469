import { visitorContextTypes } from '../actions/types';
/* eslint-disable no-case-declarations */

const initialState = {
  relevantMessages: {},
  selectedOption: {},
  generalInfo: {},
  navigationInfo: {},
  navigationHistory: {},
  selectedConversation: {},
  tooltip: {
    content: null,
    position: {
      x: 0,
      y: 0,
    },
  },
};

/**
 * This function will update the context for a specific command on a specific
 * conversationId it will return the new state as a hashmap
 * @param {Object} state - The current state of the context
 * @param { String } commandName command name
 * @param { Object } message message object from socket
 * @returns { Object } new state at the specific converationId
 * @memberof visitorContextReducer
 * @returnExample
 * {
 *  [conversationId]: {
 *    data: ['...', '...', '...'] // (no duplicate values)
 *   }
 * }
 */
const handleAddContextMessage = (state, commandName, message) => {
  let oldData;
  try {
    oldData = state[commandName][message.conversationId].data;
  } catch (error) {
    oldData = [];
  }
  return {
    [message.conversationId]: {
      data: [...new Set([...oldData, message.conversationContext])],
    },
  };
};

const expectedCommands = [
  'relevantMessages',
  'selectedOption',
  'conversationTag',
];

function visitorContextReducer(state = initialState, action) {
  switch (action.type) {
    case visitorContextTypes.ADD_CONTEXT_MESSAGE:
      const { commandName, ...message } = action.payload;
      if (!expectedCommands.includes(commandName)) return state;
      return {
        ...state,
        [commandName]: {
          ...state[commandName],
          ...handleAddContextMessage(state, commandName, message),
        },
      };
    case visitorContextTypes.SET_SELECTED_CONVERSATION:
      return {
        ...state,
        ...action.payload,
      };
    case visitorContextTypes.SET_CONTEXT_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case visitorContextTypes.SET_TOOLTIP:
      return {
        ...state,
        tooltip: {
          ...state.tooltip,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default visitorContextReducer;
