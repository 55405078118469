import styled from 'styled-components';

const Container404 = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 60px;
`;

const Icon404 = styled.i`
  font-size: 10em;
`;

export { Container404, Icon404 };
