export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export interface MessageState {
  action?: string,
  body: string,
  conversationId?: string,
  id?: string,
  message?:{
    text: string,
    type: string,
    extension?: string,
    caption?: string,
  }
  sendAt?: string,
  sender?: {
    id: string,
    type: string,
    name?: string,
    initial?: string,
  },
  type?: string,
  websiteId?: string,
  /* eslint-disable-next-line camelcase */
  response_options?: string[]
}

export interface propertiesType {
  field: string,
  value: string | { date: number; message: string } | undefined | null | boolean
}
