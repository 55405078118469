import { SET_ALL_USERS } from '../actions/types';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ALL_USERS:
      return (action.payload);
    default:
      return state;
  }
}
