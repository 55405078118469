import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'cliengo-ui';

import { translate } from '../../services/translate';
import ModalInterveneConversation from '../Modals/InterveneConversation';
import { socketActions } from '../../services/socketeerConnections';
import analytics, { EVENTS } from '../../services/analytics';
import { setLocalStorage } from '../../utils';

import { InterveneConversationContainer } from './ChatInterveneButton.style';

const ChatInterveneButton = ({
  isLoadingMessages,
  socketFunction,
  chatInfo: { available: chatItems },
  user,
}) => {
  const [showWarningDialog, setShowWarningDialog] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const history = useHistory();
  const chatId = useQuery().get('chatId') ?? chatItems[0]?.id;
  const selectedChat = chatItems.find((e) => e.id === chatId);
  const conversation = {
    id: chatId,
    websiteId: selectedChat?.websiteId,
    companyId: user.companyId,
  };
  const trackEvent = (event) => { analytics.trackEvent(event); };
  const interruptChat = async () => {
    const message = {
      action: socketActions.SEND_MESSAGE,
      body: socketActions.SHUT_UP,
      conversationId: chatId,
      message: {
        text: socketActions.SHUT_UP,
        type: 'text/plain',
      },
    };

    await socketFunction.sendMessage(message);
    socketFunction.sendAction('updateChat', conversation);
    setLocalStorage('mine', chatId);
    history.push(`/live/active/mine?chatId=${selectedChat.id}`);
  };

  const openConditionalModal = () => {
    // aqui trackear boton
    const event = {
      name: EVENTS.GO_TO_CHAT,
      category: 'live',
    };
    trackEvent(event);
    if (showWarningDialog) {
      setShowModal(true);
    } else {
      interruptChat();
    }
  };

  const sendConverseEvent = () => {
    if (!showWarningDialog) localStorage.setItem('showWarningDialog', `${showWarningDialog}`);
    else localStorage.removeItem('showWarningDialog');
    const event = {
      name: EVENTS.ENTER_CHAT,
      category: 'live',
      label: showWarningDialog,
    };
    trackEvent(event);
    closeModal();
    interruptChat();
  };

  useEffect(() => {
    setShowWarningDialog(!(localStorage.getItem('showWarningDialog') === 'false'));
  }, [isLoadingMessages]);

  return (
    <InterveneConversationContainer>

      <Button
        title={translate('visitors_section.chat_preview.chat')}
        callback={openConditionalModal}
        disabled={isLoadingMessages}
        className="button-converse"
      />
      <ModalInterveneConversation
        showModal={showModal}
        closeModal={closeModal}
        sendConverseEvent={sendConverseEvent}
        dontShowAgain={!showWarningDialog}
        dontShowAgainCallback={(v) => setShowWarningDialog(!v)}
        modalWidth="30%"
      />
    </InterveneConversationContainer>
  );
};

const mapStateToProps = (state) => ({
  chatInfo: state.chatInfo,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(ChatInterveneButton);
