import axios from 'axios';
import config from './config';

export const uploadFile = async (data) => {
  const fileInformation = {};
  const isMultiMedia = ['video', 'audio'].includes(data.type);
  const dataUpload = { file: data.file, upload_preset: config.cloudinary.uploadPresetForLive };
  const request = axios.create({
    transformRequest: [(reqData, headers) => {
      delete headers.common.Authorization; // eslint-disable-line no-param-reassign
      return reqData;
    }, ...axios.defaults.transformRequest],
  });
  const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${config.cloudinary.cloudName}/auto/upload`;
  const response = await request.post(cloudinaryUrl, dataUpload);

  if (response && response.status === 200) {
    fileInformation.url = response.data.url;
    fileInformation.size = response.data.bytes;

    if (isMultiMedia) {
      fileInformation.duration = response.data.duration;
    }
  }

  return fileInformation;
};

export default {
  uploadFile,
};
