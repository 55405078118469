import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, EllipsisLoader } from 'cliengo-ui';

import analytics, { EVENTS } from '../../../services/analytics';
import { translate } from '../../../services/translate';
import { saveSlide } from '../../../Store/actions/slide';
import { Colors, isMobile } from '../../../utils';

import {
  TopBar,
  CircleStatus,
  IconArrowChat,
  TopbarAction,
  DropdownMenu,
  ElementMenu,
  TopbarButton,
  ContactDetail,
  MobileIcon,
  ModalContent,
  ModalHeader,
  ModalBody,
} from './styles';

const ChatHeader = ({
  avatarImgUrl,
  chatbotName,
  clientStatus,
  clientStatusColor,
  callbackEndChat,
  callbackMobileBack,
  modalActionTransfer,
  modalActionEndChat,
  modalBodyTitle,
  modalBodySubTitle,
  modalBodyParagraph,
  modalActionCancel,
  showActions,
  contactIcon,
  time,
  channel,
  nroConversations,
  finishedConversation,
  selectedChat,
  tab,
}) => {
  const [showModalEndChat, setShowModalEndChat] = useState(false);
  const [showModalTransferChat, setShowModalTransferChat] = useState(false);
  const [fadeInDown, setFadeInDown] = useState(false);
  const [isLoadingEnd, setIsLoadingEnd] = useState(false);
  const [onlyOneConver, setOnlyOneConver] = useState(false);
  const dispatch = useDispatch();
  const toggleModalEndChat = () => {
    setFadeInDown(false);
    setShowModalEndChat(!showModalEndChat);
  };

  const callbackSlideInfo = () => {
    if (!isMobile()) {
      dispatch(saveSlide({ open: true, content: { id: selectedChat.id } }));
    }
  };

  /** `closeModalEndChat` - handles modal's actions
   * @param {string} [action=cancel] - action can be `cancel` || `confirm`
   */
  const closeModalEndChat = (action = 'cancel') => {
    analytics.trackEvent({
      name: EVENTS.END_CHAT,
      label: action,
    });
    if (action === 'cancel') {
      return toggleModalEndChat();
    }

    if (action === 'confirm') {
      return closeChat();
    }
  };

  const closeChat = () => {
    setIsLoadingEnd(true);
    if (nroConversations === 1) {
      setOnlyOneConver(true);
    }
    setTimeout(() => {
      toggleModalEndChat();
      setIsLoadingEnd(false);
      setOnlyOneConver(false);
    }, 2000);
    setTimeout(callbackEndChat, 2000);
  };

  const toggleModalTransferChat = () => {
    setFadeInDown(false);
    setShowModalTransferChat(!showModalTransferChat);
  };

  const dropDownMenu = () => {
    setFadeInDown(!fadeInDown);
  };

  const trackContactDetailsEvent = () => {
    analytics.trackEvent({
      name: EVENTS.CONTACT_DETAIL,
      label: 'mine',
    });
  };

  return (
    <>
      <TopBar id="chatHeader">
        <div>
          <div className="topbar-title-container">
            <IconArrowChat onClick={callbackMobileBack}>
              <i className="icon-cleft" />
            </IconArrowChat>
            {avatarImgUrl && (
              <div
                className="topbar-avatar"
                style={{ backgroundImage: `url("${avatarImgUrl}")` }}
              />
            )}
            <div className="topbar-text">
              <div className="topbar-title">
                {chatbotName !== 'Cliente' ? chatbotName : translate('client')}
              </div>
              <div className="topbar-subtitle">
                {time || (
                  <>
                    <CircleStatus color={finishedConversation ? Colors.gold : clientStatusColor} />
                    {finishedConversation ? translate('visitors_section.offline') : clientStatus}
                  </>
                )}

              </div>
            </div>
          </div>
          <TopbarAction>
            {
              showActions && (
                <>
                  <TopbarButton onClick={toggleModalTransferChat} hide>
                    <i className="icon-transfer-to" />
                    {modalActionTransfer}
                  </TopbarButton>
                  {
                    channel === 'WEB' && tab === 'mine'
                    && (
                      <TopbarButton onClick={toggleModalEndChat}>
                        <span><i className="icon-cancelar" /></span>
                        {modalActionEndChat}
                      </TopbarButton>
                    )
                  }
                </>
              )
            }

            <ContactDetail
              hiddeIcon={contactIcon}
              onClick={() => {
                trackContactDetailsEvent();
                callbackSlideInfo();
              }}
            >
              <i className="icon-informacion" />
            </ContactDetail>

            {
              showActions && (
                <>
                  <MobileIcon onClick={dropDownMenu}>
                    <i className="icon-circle" />
                    <i className="icon-circle" />
                    <i className="icon-circle" />
                  </MobileIcon>
                  <DropdownMenu className={fadeInDown && 'fadeInDown'} fadeInDown={fadeInDown}>
                    <ElementMenu>
                      <TopbarButton onClick={toggleModalTransferChat}>
                        <i
                          className="icon-transfer-to"
                        />
                        {modalActionTransfer}
                      </TopbarButton>
                      <TopbarButton onClick={toggleModalEndChat}>
                        <span>
                          <i
                            className="icon-cancelar"
                          />
                        </span>
                        {modalActionEndChat}
                      </TopbarButton>
                    </ElementMenu>
                  </DropdownMenu>
                </>
              )
            }
          </TopbarAction>
        </div>

        {/* MODAL PARA FINALIZAR CHAT */}
        <Modal
          animation="zoom"
          visible={showModalEndChat}
          showDialog={false}
          onClose={toggleModalEndChat}
        >
          <ModalContent>
            <ModalHeader>
              <i onClick={closeModalEndChat} className="icon-cerrar" />
            </ModalHeader>
            <ModalBody>
              <h2>{modalBodyTitle}</h2>
              <h3>{onlyOneConver ? 'Finalizando' : modalBodySubTitle}</h3>
              <p>
                <i className="icon-exclamation-triangle" />
                {onlyOneConver ? 'Redirigiendo a visitantes' : modalBodyParagraph}
              </p>

              <Button
                title={modalActionCancel}
                background="white"
                border="#DDDDDD"
                color="#515151"
                type="button"
                callback={closeModalEndChat}
                disabled={isLoadingEnd}
              />
              <Button
                title={isLoadingEnd ? <EllipsisLoader /> : modalActionEndChat}
                background="#EB2963"
                border="#EB2963"
                color="#FFFFFF"
                type="button"
                disabled={isLoadingEnd}
                callback={() => closeModalEndChat('confirm')}
                className="endChat"
              />
            </ModalBody>

          </ModalContent>
        </Modal>
      </TopBar>
    </>
  );
};

ChatHeader.propTypes = {
  clientStatus: PropTypes.string.isRequired,
  callbackEndChat: PropTypes.func,
  avatarImgUrl: PropTypes.string,
};

ChatHeader.defaultProps = {
  avatarImgUrl: null,
  callbackEndChat: () => false,
};
export default ChatHeader;
