import { CLOSE_SLIDE, OPEN_SLIDE, SET_APP_SLIDE } from '../actions/types';

const initialState = {
  open: false,
  content: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let newPayload = Object.assign(initialState, payload);
  switch (type) {
    case SET_APP_SLIDE:
      return ({ ...state, ...newPayload });
    case OPEN_SLIDE:
      return ({ ...state, open: true });
    case CLOSE_SLIDE:
      return { ...state, content: null, open: false };
    default:
      return state;
  }
}
