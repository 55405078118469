import store from '../../../Store';

/* eslint-disable import/prefer-default-export */
export const getVisitorQueries = () => {
  const { visitorContext } = store.getState();
  const { conversationId, relevantMessages } = visitorContext;
  if (conversationId) {
    if (relevantMessages[conversationId]) {
      return [...relevantMessages[conversationId].data].reverse().slice(0, 4);
    }
  }
};
