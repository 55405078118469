import { SET_CHAT_INFO } from './types';

const setChats = (chats, action, user) => ({
  type: action,
  payload: { chats, user },
});

const updateChatInfo = (chats, user, action = SET_CHAT_INFO) => async (dispatch) => {
  dispatch(setChats(chats, action, user));
};

export default updateChatInfo;

export { updateChatInfo };
