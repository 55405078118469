/* eslint-disable import/prefer-default-export */

/**
 * This function will return true if the message is a command.
 * Message is considered a command if it starts with ! and have not spaces after it.
 * example: !shutup !composing !close-conversation !askForVideoCall !askForPhoneCall
 * @param { Object } msg // message object from socketeer
 * @returns { Boolean } // true if the message is a command
 */
export function messageIsCommand(msg) {
  try {
    if (msg.body) {
      return msg.body?.startsWith('!') && msg.body.split(' ').length === 1;
    }
  } catch (error) {
    return false;
  }
  return false;
}
