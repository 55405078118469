import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { Button, ConversationBubble } from 'cliengo-ui';

import config from '../../../services/config';
import { TitleChatWindow } from '../../../GlobalStyles';
import {
  MainContainerPanel, SubcontainerPanel, ChatHeaderPanel, ChatBodyPanel, ChatFooterPanel,
} from '../../../GlobalStyles/Layouts';
import ModalInterveneConversation from '../../Modals/InterveneConversation';
import { ConversationMock } from '../../ChatsComponents';
import { translate } from '../../../services/translate';
import EmptyTable from '../../EmptyTable/EmptyTable';
import { Images } from '../../../Constants';
import WarningMessage from '../../WarningMessage/WarningMessage';

import {
  Icon, ContactTexting, ChatBodyContainer,
  WarningContainer,
  ConversationMeta,
} from './ConversationDetails.styles';

const ConversationDetailsView = ({
  data,
  chatingUser,
  closeSlide,
  chatInfo,
  idChat,
  sendConverseEvent,
  openModal,
  closeModal,
  showModal,
  isLoading,
  dontShowAgain,
  dontShowAgainCallback,
  messagesRef,
  hideFooter,
  websites,
  users,
  ...props
}) => {
  const [currentChat, setCurrentChat] = useState({});
  const [website, setWebsite] = useState({});
  const [mappedMessages, setMappedMessages] = useState([]);
  const getConversation = async (converId, isItHistory) => {
    let conversation;

    try {
      if (isItHistory) {
        const response = await axios.get(
          `${config.urls.API_URL}/conversations/${converId}`,
          { withCredentials: true },
        );

        if (response.status === 200) conversation = response.data;
      } else {
        const onlineConversations = [...chatInfo.available, ...chatInfo.taken];
        conversation = onlineConversations.find((chat) => chat.id === converId);
      }
    } catch (error) {
      console.error(`error getting the chat ${error}`);
    }

    setCurrentChat(conversation);
  };
  const { pathname } = useLocation();
  const splittedUrl = pathname.split('/');
  const isHistory = splittedUrl[splittedUrl.length - 1] === 'history';
  const hasExternalOperator = useRef(false);

  useEffect(() => {
    getConversation(idChat, isHistory);
  }, []);

  useEffect(() => {
    const site = websites?.find((site) => site.id === currentChat?.websiteId);
    setWebsite(site);
  }, [currentChat]);

  useEffect(() => {
    setMappedMessages(mapMessages());
  }, [website, data]);

  const noInfoWrapper = () => {
    const dataEmpty = {
      img: Images.messagesErrorInConversation,
      title: translate('empty_state_section.no_message_error.error_title'),
      subtit: translate('empty_state_section.no_message_error.error_content'),
    };

    return <EmptyTable data={dataEmpty} />;
  };

  const account = useSelector((state) => state.auth?.account);
  const canIntervene = () => !chatingUser
    || (
      account.labs.force_intervention
      && account.labs.whatsapp_premium
      && (
        currentChat?.channel === 'WHATSAPP'
      )
    );

  const getParticipantName = (participantId, options) => {
    const found = options.find((user) => user.id === participantId);
    return found?.name;
  };
  const mapMessages = () => {
    let retJsx;

    if (data.length > 0) {
      retJsx = data.map((e, index) => {
        if (e.type === 'externalOperator') {
          hasExternalOperator.current = true;
          const { websiteId } = currentChat;

          const chatSite = websites?.find((site) => site.id === websiteId);
          e.name = chatSite ? `@${website?.title}` : 'Desconocido';
        }
        e.name = e.name !== 'Desconocido' ? e.name : `@${website?.title}`;
        if (e.type === 'user') e.name = getParticipantName(e.sender, users) ?? chatingUser;
        return (hideFooter
          ? (
            <>
              {index === 0
                && (
                  <ConversationMeta>
                    {moment(e.sentAt).format('MM/DD/YYYY h:mm a')}
                    <br />
                    {e.name}
                    {' '}
                    {translate('my_chats_section.interacctions_with_conversation.joined_chat')}
                  </ConversationMeta>
                )}
              <ConversationBubble {...e} sentAt={e.sendAt} key={e.id} channel={currentChat?.channel} />
              {(index + 1) === data.length
                && (
                  <ConversationMeta>
                    {moment(e.sentAt).format('MM/DD/YYYY h:mm a')}
                    <br />
                    {e.type === 'robot' ? data.find((user) => user.type === 'visitor').name : e.name}
                    {' '}
                    {translate('my_chats_section.interacctions_with_conversation.leave_chat')}
                  </ConversationMeta>
                )}
            </>
          )
          : <ConversationBubble {...e} sentAt={e.sendAt} key={e.id} channel={currentChat?.channel} />);
      });
    } else {
      retJsx = noInfoWrapper();
    }

    return retJsx;
  };

  return (
    <MainContainerPanel className={props.className} id="conversationDetailsPanel">
      <SubcontainerPanel>
        {/* HEADER DEL PANEL */}
        <ChatHeaderPanel>
          <TitleChatWindow>Chat</TitleChatWindow>
          <Icon className="icon icon-close-panel" onClick={closeSlide} />
        </ChatHeaderPanel>

        {/* BODY DEL PANEL */}
        <ChatBodyContainer isExternalOperator={hasExternalOperator}>
          <ChatBodyPanel withFooter ref={messagesRef}>
            {
              isLoading
                ? <ConversationMock isLoading />
                : (mappedMessages)
            }
          </ChatBodyPanel>
          <WarningContainer>
            <WarningMessage isExternalOperator={hasExternalOperator.current && !isHistory} websiteName={website?.title} height="80px" />
          </WarningContainer>
        </ChatBodyContainer>
        {/* FOOTER DEL PANEL */}
        {!hideFooter
          && (
            <ChatFooterPanel>
              {canIntervene()
                ? (
                  <Button
                    title={translate('visitors_section.chat_preview.chat')}
                    callback={openModal}
                    disabled={isLoading}
                    className="button-converse"
                  />
                )
                : (
                  <ContactTexting>
                    <i className="icon-info-circle" />
                    <span className="userName">{chatingUser}</span>
                    {translate('visitors_section.chat_preview.is_chatting_with_contact')}
                  </ContactTexting>
                )}
            </ChatFooterPanel>
          )}
      </SubcontainerPanel>

      {/* MODAL PARA INTERVENIR LA CONVERSACION */}
      <ModalInterveneConversation
        closeModal={closeModal}
        showModal={showModal}
        sendConverseEvent={sendConverseEvent}
        dontShowAgainCallback={dontShowAgainCallback}
        dontShowAgain={dontShowAgain}
        idToInsertIn="conversationDetailsPanel"
        containerClass="containerModal"
      />
    </MainContainerPanel>
  );
};

export default ConversationDetailsView;
