import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HamburgerButton, Button } from 'cliengo-ui';

import { saveAppSection } from '../../../Store/actions/navigation';
import { saveSlide } from '../../../Store/actions/slide';
import config from '../../../services/config';
import { translate } from '../../../services/translate';
import { StateAllChats } from '../../../Interfaces/AllChats';

import TopMenu from './top-menu';
import {
  NavLogo, TopRight, TopLeft, Wrapper, TopBar, Logo,
} from './style';

interface TopbarProps {
  hamburgerHandler: () => void;
  isLive: boolean;
  isOpenHamburger: boolean;
}
const Topbar = ({ hamburgerHandler, isLive, isOpenHamburger }: TopbarProps) => {
  const dispatch = useDispatch();
  const slidePanel = useSelector((state: StateAllChats) => state.slidePanel);

  const changeSection = (changeSection: string) : void => {
    dispatch(saveSlide(slidePanel));
    saveAppSection(changeSection);
  };
  const returnToCrm = () : void => window.location.replace(`${config.urls.CRM}/crm/dashboard`);

  return (
    <Wrapper>
      <TopBar>

        <TopLeft>
          <NavLogo>
            {
                !isLive
                  && (
                  <HamburgerButton
                    onClick={hamburgerHandler}
                    className="hamburgerButton"
                    type="Elastic"
                    open={isOpenHamburger}
                  />
                  )
              }
            <Logo
              onClick={() => changeSection('live')}
              to="/live"
            />
          </NavLogo>
        </TopLeft>
        <TopMenu />

        <TopRight>
          <Button
            className="return-to-crm"
            title={translate('return_crm')}
            onClick={returnToCrm}
          />
        </TopRight>
      </TopBar>
    </Wrapper>
  );
};

export default Topbar;
