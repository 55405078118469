/* eslint-disable camelcase, no-underscore-dangle */
import axios from 'axios';

import config from './config';

const assignConversation = (
  conversationId: string,
  userId: string,
) => axios.post(`${config.urls.API_URL}/conversations/${conversationId}/assign`,
  {
    assignedTo: userId,
  });

export default assignConversation;
