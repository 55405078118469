import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { translate } from '../../services/translate';

import Tooltip from '../Tooltip/Tooltip';
import { Colors } from '../../utils';

const FileCard = ({
  file,
  removeElement,
}) => {
  const getFileName = (file) => {
    if (!file) return '';
    const { extension } = file;
    const name = (file.name.split(`.${extension}`))[0];
    let parcedName = name;

    if (name.length > 7) {
      parcedName = name.substring(0, 7);
    }

    return `${parcedName.trim()}...${extension}`;
  };
  const getFileThumbnail = (file) => {
    const thumbnails = {
      image: (<img src={file.url} alt={file.name} />),
      video: (<i className="icon icon-videocam" />),
      audio: (<i className="icon icon-music" />),
      file: (<i className="icon icon-file_copy" />),
    };

    return thumbnails[file.type] || thumbnails.file;
  };

  return (
    <Card>
      <CardHeader>
        <Tooltip
          direction="top"
          alignmentX="-45%"
          alignmentY="-20%"
          tooltipColor={Colors.colorFontGeneral}
          tooltipWidth="117.5px"
          tooltipHeight="12px"
          content={translate('my_chats_section.chat_multimedia.remove_file')}
        >
          <CardCloseButton className="card__exit"><i className="icon-close" onClick={() => removeElement(file)} /></CardCloseButton>
        </Tooltip>
      </CardHeader>
      <FileIcon>
        {
          getFileThumbnail(file)
        }
      </FileIcon>
      <FileName>{getFileName(file)}</FileName>
    </Card>
  );
};

const Card = styled.div`
  min-width: 120px;
  margin: 4px;
  padding-bottom: 20px;
  height: 65px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border:1px solid ${Colors.cliengoMain};
  border-radius: 8px;
  transition: all 0.2s;
  .icon{
    align-self: auto;
    font-size: 30px;
    display: flex;
    margin-left: 25%;
  }
  :hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  }
`;
const CardHeader = styled.div`
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  width: 90%;
  justify-content: flex-end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 1s;
  transition: all 1s;
  left: 0;
`;
const CardCloseButton = styled.p`
  display: flex;
  margin-left: 95%;
  text-decoration: none;
  color: ${Colors.cliengoMain};
  align-self: end;
  margin-left: 99%;
  font-size: 9px;
  margin-top: auto;
`;

const FileName = styled.p`
  position: relative;
  margin-top: -10%;
`;

const FileIcon = styled.div`
  margin-left: 15%;
  img{
    height: 60px;
    width: 45px;
    margin-left: -20%;
    margin-top: -20%;
  }
`;

FileCard.propTypes = {
  file: PropTypes.object,
  removeElement: PropTypes.func,
};

FileCard.defaultProps = {
  file: {},
  removeElement: () => { },
};

export default FileCard;
