import store from '../../Store';
import axios from 'axios';
import { hasLabSendConversationContext } from '../../Components/VisitorContext/helpers/hasLabSendConversationContext';
import config from '../../services/config';
import { visitorContextTypes } from './types';

export const addContextInfo = (contextInfo) => {
  store.dispatch({
    type: visitorContextTypes.ADD_CONTEXT_MESSAGE,
    payload: contextInfo,
  });
};

/**
 * Use axios to get the context info from the server
 * @param {String} conversationId
 */
async function getPersistedConversation(conversationId, websiteId) {
  const operators = store.getState().users;
  try {
    const { data } = await axios.get(`${config.urls.API_URL}/conversations/${conversationId}`);

    const { data: contextData } = await axios.get(
      `${config.urls.API_URL}/projects/${websiteId}/conversationContext/${conversationId}`,
    );

    return {
      generalInfo: {
        visitorName: data.visitorName || data.clientName,
        visitorPhone: data.visitorPhone,
        visitorEmail: data.visitorEmail,
        visitorLocation: data.visitorLocation,
        visitorChannel: data.channel?.toLowerCase(),
        operatorAssigned:
          operators.find(
            (operator) =>
              operator.id ===
              data.participants.find(
                (participant) => participant.type === 'user',
              )?.id,
          )?.name || '',
      },
      navigationInfo: {
        url: data.metadata ? data.metadata.conversationUrl : undefined,
        site: data.metadata ? data.metadata.conversationUrl : undefined,
      },
      relevantMessages: {
        [conversationId]: {
          data: [...new Set([...(contextData.relevant_messages || [])])],
        },
      },
      selectedOption: {
        [conversationId]: {
          data: [...new Set([...(contextData.selected_options || [])])],
        },
      },
    };
  } catch (error) {
    return {};
  }
}

export const setPersistedConversation = async (websiteId, conversationId) => {
  if (!conversationId || !websiteId) return;
  const contextInfo = await getPersistedConversation(conversationId, websiteId);
  store.dispatch({
    type: visitorContextTypes.SET_CONTEXT_INFO,
    payload: {
      ...contextInfo,
      hasLabSendConversationContext: hasLabSendConversationContext(websiteId),
      websiteId,
      selectedConversation: conversationId,
    },
  });
};

export const setSelectedConversation = (websiteId, conversationId) => {
  setPersistedConversation(websiteId, conversationId).then(() => {
    store.dispatch({
      type: visitorContextTypes.SET_SELECTED_CONVERSATION,
      payload: {
        websiteId,
        conversationId,
      },
    });
  });
};

export const setVisitoContextTooltip = (tooltipContent) => {
  store.dispatch({
    type: visitorContextTypes.SET_TOOLTIP,
    payload: tooltipContent,
  });
};
