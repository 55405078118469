import { Colors } from '../../utils';

const styleAccordion = {
  container: {
    width: '100%',
    boxSizing: 'border-box',
  },
  header: {
    width: '100%',
    height: '40px',
    lineHeight: '40px',
    borderBottomWidth: '1px',
    borderTopWidth: '1px',
    borderBottomColor: Colors.lightGray,
    borderTopColor: Colors.lightGray,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  label: {
    color: Colors.cliengoMain,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  icon: {
    color: '#515151',
    paddingRight: '15px',
  },
};

export default styleAccordion;
