import moment from 'moment';

import {
  ADD_CHAT, REMOVE_CHAT, SET_CHAT_INFO, UPDATE_CHAT, UPDATE_ONE_CHAT,
} from '../actions/types';
import {
  getOldChatItems, setOldChatItems, getPreview, capitalize, getLocalStorage, setLocalStorage,
} from '../../utils';

const initialState = {
  available: [],
  taken: [],
  mine: [],
  removed: [],
};

const cleanOldChatItems = () => {
  let oldChatItems = getOldChatItems();

  oldChatItems = oldChatItems.filter((chat) => moment().diff(moment(chat.remotionTimeStamp), 'minutes') < 5);
  setOldChatItems(oldChatItems);
};

export default function chatinfoReducer(state = initialState, action) {
  const user = action?.payload?.user;
  switch (action.type) {
    case SET_CHAT_INFO: {
      const ret = { ...state };
      let mine = [];
      const chats = { ...action.payload?.chats };
      const unReadStorage = getLocalStorage('unRead');

      if (user) {
        if (chats.available) {
          if (user.allowedWebsiteIds.length > 0 || user.permissions.includes('admin')) {
            ret.available = chats.available.filter(
              (chat) => user.permissions.includes('admin') || user.allowedWebsiteIds.includes(chat.websiteId),
            );
          } else {
            ret.available = chats.available;
          }
        }
        if (chats.taken) {
          if (user.allowedWebsiteIds.length > 0 || user.permissions.includes('admin')) {
            ret.taken = chats.taken.filter(
              (chat) => user.permissions.includes('admin') || user.allowedWebsiteIds.includes(chat.websiteId),
            );
          } else {
            ret.taken = chats.taken;
          }
          mine = ret.taken.filter(
            (conv) => conv.participants.find((pt) => pt.type === 'user' && pt.id === user.id),
          ).map((chat) => {
            const unRead = unReadStorage?.find((unReadId) => unReadId === chat.id);
            return {
              ...chat,
              unRead: unRead ?? false,
            };
          }) || [];
          let oldChatItems = getOldChatItems();

          oldChatItems = oldChatItems.filter((chat) => {
            const isTaken = mine?.find((taken) => taken.id === chat.id);
            let ret = false;
            if (!isTaken && moment().diff(moment(chat.remotionTimeStamp), 'minutes') < 5) {
              const unRead = unReadStorage?.find((unReadId) => unReadId === chat.id);
              // eslint-disable-next-line no-param-reassign
              chat.unRead = unRead ?? false;
              ret = true;
            } else {
              const unReadStorage = getLocalStorage('unRead');
              setLocalStorage('unRead', unReadStorage?.filter((unRead) => unRead !== chat.id) ?? []);
            }
            return ret;
          });
          mine = [...mine, ...oldChatItems];
          setOldChatItems(oldChatItems);
        }
      }
      ret.mine = mine;
      const keys = Object.keys(ret);

      keys.forEach((key) => {
        ret[key] = ret[key]?.map((chat) => {
          const date = new Date(chat.lastMessageDate);
          const chatLastMessage = chat?.lastMessage?.message;
          const lastMessage = getPreview(chat, capitalize(chat.visitorName),
            chatLastMessage ?? chat.lastMessage, date);
          const lastMessageTime = moment().diff(moment(chat.lastMessageDate), 'minutes');

          return { ...chat, lastMessage, lastMessageTime };
        });
      });

      return ({ ...ret });
    }
    // Agrega una nueva conversacion
    case ADD_CHAT: {
      const { chats, user } = action.payload;
      const ret = { ...state };

      if (user.allowedWebsiteIds) {
        if (user.permissions.includes('admin') || user.allowedWebsiteIds.includes(chats.websiteId)) {
          const isAvailable = state.available.find(
            (chat) => chat.id === chats.id,
          );
          const isTaken = state.taken.find(
            (chat) => chat.id === chats.id,
          );

          if (!isAvailable && !isTaken) {
            const date = new Date(chats.lastMessageDate);
            const lastMessage = getPreview(chats, capitalize(chats.visitorName), chats.lastMessage, date);
            // chats.lastMessageDate = sendAt.toString();
            const lastMessageTime = moment().diff(moment(chats.lastMessageDate), 'minutes');
            ret.available.push({ ...chats, lastMessage, lastMessageTime });
          }
        }
      }
      ret.mine = ret.mine.filter((chat) => moment().diff(moment(chat.remotionTimeStamp), 'minutes') < 5);
      cleanOldChatItems();
      return (ret);
    }
    // elimina una conversacion
    case REMOVE_CHAT: {
      let ret = { ...state };
      let newList = state.available.filter((e) => e.id !== action.payload.chats);
      let stateInfo = 'available';
      ret = { ...ret, [stateInfo]: newList };
      const removed = state.taken.find((chat) => chat.id === action.payload.chats);
      if (removed) ret.removed.push(removed);
      newList = state.taken.filter((e) => e.id !== action.payload.chats);
      stateInfo = 'taken';
      let mine2 = newList.filter(
        (conv) => conv.participants.find((pt) => pt.type === 'user' && pt.id === user.id),
      ) || [];

      mine2 = mine2.filter((chat) => moment().diff(moment(chat.remotionTimeStamp), 'minutes') < 5);

      if (removed) {
        const oldChatItems = getOldChatItems();
        const isStored = oldChatItems.find((chat) => chat.id === removed.id);

        if (isStored) {
          removed.remotionTimeStamp = moment();
          if (!mine2.find(((chat) => chat.id === removed.id))) {
            mine2.push(removed);
          }
        } else {
          const newRemoved = {
            ...removed,
            lastMessageTime: moment().diff(moment(removed.lastMessageDate), 'minutes'),
            remotionTimeStamp: moment(),
            finishedConversation: true,
          };
          oldChatItems.push(newRemoved);
          mine2.push(newRemoved);
        }
        setOldChatItems(oldChatItems);
        cleanOldChatItems();
      }
      ret = {
        ...ret,
        taken: newList,
        mine: mine2,
      };

      return ret;
    }

    case UPDATE_CHAT: {
      const { chats, user } = action.payload;
      const ret = { ...state };
      const isAvailable = ret.available.find((c) => c.id === chats.id);
      const isTaken = ret.taken.find((c) => c.id === chats.id);
      const participantFind = chats.participants?.find((pt) => pt.type === 'user');
      const imTheParticipant = participantFind && participantFind.id === user.id;
      const date = new Date(chats.lastMessageDate);
      const lastMessage = getPreview(chats, capitalize(chats.visitorName), chats.lastMessage, date);
      const lastMessageTime = moment().diff(moment(chats.lastMessageDate), 'minutes');
      const newChat = ({ ...chats, lastMessage, lastMessageTime });

      if (isAvailable) {
        if (participantFind) {
          ret.available = ret.available.filter((chat) => chat.id !== newChat.id);
          ret.taken.push(newChat);
          if (imTheParticipant) ret.mine.unshift(newChat);
        } else {
          ret.available = ret.available.map((c) => (c.id === newChat.id ? { ...newChat } : c));
        }
      }

      if (isTaken) {
        const unReadStorage = getLocalStorage('unRead');
        const unRead = unReadStorage?.find((unReadId) => unReadId === chats.id);
        if (imTheParticipant) {
          ret.mine = ret.mine.map((chat) => (chat.id === newChat.id ? { ...newChat, unRead: unRead ?? false } : chat));
        }
        ret.taken = ret.taken.map((chat) => (chat.id === newChat.id ? { ...newChat, unRead: unRead ?? false } : chat));
      }

      return ret;
    }

    case UPDATE_ONE_CHAT: {
      const { id, properties } = action.payload.chats;
      const ret = { ...state };

      if (properties) {
        properties.forEach((property) => {
          const { field, value } = property;
          ret.available = ret.available.map((chat) => {
            const retChat = { ...chat };
            if (chat.id === id) {
              retChat[field] = value;
            }
            return retChat;
          });

          ret.taken = ret.taken.map((chat) => {
            const retChat = { ...chat };
            if (chat.id === id) {
              retChat[field] = value;
            }
            return retChat;
          });

          ret.mine = ret.mine.map((chat) => {
            const retChat = { ...chat };
            if (chat.id === id) {
              retChat[field] = value;
            }
            return retChat;
          });
        });
      }
      return ret;
    }

    default:
      return state;
  }
}
