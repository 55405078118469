/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import axios from 'axios';

import { IVisitorContext } from '../../../../Interfaces/VisitorContext';
import { translate } from '../../../../services/translate';
import { handleLocalTags } from '../../../../utils/handleLocalTags';
import { TagButton } from '../TagButton/TagButton.component';
import { IColors } from '../../../../utils/colors';
import { socketActions } from '../../../../services/socketeerConnections';
import { Modal } from '../Modal/Modal.component';
import { setLocalStorage } from '../../../../utils';
import config from '../../../../services/config';
import store from '../../../../Store';

import { IContextTag, IContextTagContainer } from './ContextTagContainer.types.d';

const TAG_COLORS: { [key: string]: keyof IColors } = {
  SHOULD_INTERVENE: 'danger',
};

export const ContextTagContainer = ({ conversationId, socket, accountId }: IContextTagContainer) => {
  const [tag, setTag] = useState<IContextTag | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(
    () => localStorage.getItem('showWarningDialog') === 'true',
  );
  const history = useHistory();
  const { featureLittenMyChatsLive778 } = useFlags();

  const { visitorContext } = useSelector((state: IVisitorContext) => state);

  useEffect(() => {
    if (conversationId) {
      const localTags = handleLocalTags.get() as unknown as IContextTag;
      if (localTags) {
        const parsedTag = localTags[conversationId as keyof typeof localTags];
        setTag((parsedTag as unknown as IContextTag) || null);
      }
    }
  }, [visitorContext]);

  function handleOnDontShowAgain(e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    setShowWarningDialog(e.target.checked);
    localStorage.setItem('showWarningDialog', JSON.stringify(e.target.checked));
  }

  function toggleModal(e: React.MouseEvent<HTMLButtonElement>) {
    if (tag?.conversationTag === 'SHOULD_INTERVENE') {
      e.stopPropagation();
      e.preventDefault();
      if (showWarningDialog) {
        assign();
      } else {
        setShowModal(!showModal);
      }
    }
  }

  function handleIntervention(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();
    if (!showWarningDialog) localStorage.setItem('showWarningDialog', `${String(showWarningDialog)}`);
    else localStorage.removeItem('showWarningDialog');
    setShowModal(false);
    assign();
  }
  const dispatch = useDispatch();

  const assign = () => {
    if (featureLittenMyChatsLive778) {
      // eslint-disable-next-line no-void
      void assignConversation();
    } else {
      interruptChat();
    }
  };

  const interruptChat = () => {
    const conversation = {
      id: conversationId as string,
      websiteId: tag?.websiteId as string,
      companyId: accountId as string,
    };
    const message = {
      action: socketActions.SEND_MESSAGE,
      body: socketActions.SHUT_UP,
      conversationId,
      message: {
        text: socketActions.SHUT_UP,
        type: 'text/plain',
      },
    };
    socket.sendMessage(message);
    socket.sendAction('updateChat', conversation);

    dispatch({
      type: 'SET_SLIDE',
      payload: { open: true, content: { id: conversationId } },
    });
    localStorage.setItem('openContext', 'true');
    setLocalStorage('mine', conversationId);
    history.push(`/live/active/mine?chatId=${conversationId as string}`);
  };

  const assignConversation = async () => {
    const { id } = store.getState().auth.user as JsonObject;
    await axios.post(`${config.urls.API_URL}/conversations/${String(conversationId)}/assign`, {
      assignedTo: id,
    });
    setLocalStorage('mine', conversationId);
    history.push({
      pathname: '/live/active/mine',
      search: `?chatId=${String(conversationId)}`,
    });
  };

  if (!tag) return null;

  return (
    <>
      <span>
        <TagButton
          color={TAG_COLORS[tag.conversationTag as keyof typeof TAG_COLORS]}
          text={translate(`visitor_context.tags.${tag.conversationTag}`) ?? tag.conversationTag}
          handler={toggleModal}
        />
      </span>
      <Modal
        showWarningDialog={showWarningDialog}
        onDontShowAgain={handleOnDontShowAgain}
        onIntervention={handleIntervention}
        show={showModal}
        onClose={toggleModal}
      />
    </>
  );
};

export default ContextTagContainer;
