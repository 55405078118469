import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from '../../utils';

const Tooltip = ({
  delay,
  children,
  direction,
  content,
  alignmentX,
  alignmentY,
  tooltipColor,
  tooltipWidth,
  tooltipHeight,
}) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <Wrapper
      alignmentX={alignmentX}
      alignmentY={alignmentY}
      tooltipWidth={tooltipWidth}
      tooltipHeight={tooltipHeight}
    >
      <GlobalStyle tooltipColor={tooltipColor || Colors.cliengoMain} />

      <div
        className="Tooltip-Wrapper"
        // When to show the tooltip
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
      >
        {/* Wrapping */}
        {children}
        {active && (
          <div className={`Tooltip-Tip ${direction || 'top'}`}>
            {/* Content */}
            <Link>{content}</Link>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const GlobalStyle = createGlobalStyle`
  :root {
    --tooltip-text-color: white;
    --tooltip-background-color: ${(props) => (props.tooltipColor)};
    --tooltip-margin: 180px;
    --tooltip-arrow-size: 6px;
  }
`;

const Link = styled.div`
white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
     white-space: -pre-wrap;      /* Opera 4-6 */
     white-space: -o-pre-wrap;    /* Opera 7 */
     white-space: pre-wrap;       /* css-3 */
     word-wrap: break-word;       /* Internet Explorer 5.5+ */
     word-break: normal;
     white-space: normal;
`;
const Wrapper = styled.div`

  /* Wrapping */
  .Tooltip-Wrapper {
    display: inline-block;
    position: relative;
  }

  /* Absolute positioning */
  .Tooltip-Tip {
    position: absolute;
    bottom:18px;
    border-radius: 4px;
    left: 50%;
    padding: 10px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    line-height: 1;
    z-index: 1002;
    min-height: 11px;
    width: ${(props) => (props.tooltipWidth || '180px')};
    height: ${(props) => (props.tooltipHeight || '11px')};
  }

  /* CSS border triangles */
  .Tooltip-Tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }

  /* Absolute positioning */
  .Tooltip-Tip.top {
    transform: ${(props) => (`translateX(${props.alignmentX || '-80%'}) translateY(${props.alignmentY || '0'});`)}
  }
  /* CSS border triangles */
  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: var(--tooltip-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
  }
`;

export default Tooltip;
