import styled from 'styled-components';

export const VisitorContextContainer = styled.div`
  box-sizing: border-box;
  height: calc(100vh - 55px);
  width: 24%;
  min-width: 320px;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1003;
  background: #fff;
  box-shadow: rgb(0 0 0 / 10%) -3px 0px 10px;
  animation: slide-in-right 0.3s ease-in-out;
  main {
    height: calc(100vh - 55px - 56px);
    overflow-y: auto;
  }
  @keyframes slide-in-right {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export const ModuleContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid rgb(226, 226, 226);
  p {
    margin: 0;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .icon {
    color: #515151;
  }
`;

export const EmptyContainer = styled.p`
  color: #aaa;
`;
