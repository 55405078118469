import styled from 'styled-components';

import { Colors } from '../../../utils';

const Subtitle = styled.p`
  color: #7868C3;
  font-size: 14px;
  font-weight:bold;
`;

const Square = styled.div`
  width: ${(props) => props.width}%;
  height: 10px;
  background: #F5F5F5;
  border-radius: 6px;
  margin-bottom: 20px;
`;

const EmptyContainer = styled.div`
  padding: 85px 10px 0 10px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  flex: 1;
  &:not(:last-child){
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 10px;
  }

`;

const Link = styled.div`
  width:auto;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
`;
const Spanpadding = styled.span`
  display:flex;
  align-items:center;
  margin-bottom: 10px;
  strong{
    margin-right: 5px;
  }
`;

const Icon = styled.i`
  color: ${(props) => (props.iconCliengoViolet ? Colors.cliengoMain : Colors.colorFontGeneral)};
  font-size: 18px;
  cursor: pointer;
  margin-right:10px;
`;

const ContactTexting = styled.p`
  text-align: left;
  font-weight: bold;
  margin: 0 0 0 45px;
  width: 100%;
  text-indent: -26px;
}
  i{
    color: ${Colors.gold};
    margin-right: 10px;
  }
`;

export {
  Icon,
  ContactTexting,
  Subtitle,
  Spanpadding,
  Block,
  EmptyContainer,
  Square,
  Link,
};
