import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConversationBubble } from 'cliengo-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

import EmojiPicker from '../../EmojiPicker/EmojiPicker';
import { translate } from '../../../services/translate';
import { Colors } from '../../../utils';

import ConversationMock from './ConversationMock';
import ChatHeader from './ChatHeader';
import ChatFooter from './ChatFooter';
import {
  ChatContainer,
  ChatWindow,
  ChatboxBody,
  MessagesWindow,
  ConversationMeta,
} from './styles';

const ChatConversation = ({
  selectedChat,
  messages,
  handlerEndChat,
  handlerMobileBack,
  handlerChatBoxPressed,
  contactIcon,
  showActions,
  time,
  initialDate,
  clientStatus,
  clientStatusColor,
  isLoadingMessages,
  nroConversations,
  operatorName,
  lastMessageDate,
  tab,
  socketFunction,
  users,
}) => {
  const { websites } = useSelector((state) => state);
  const { featureLiveWarnignuserdisconnectedLive257 } = useFlags();
  const messagesRef = useRef(null); // referencia a la ventana donde se ven los mnesajes
  const hasExternalOperator = useRef(false); // referencia a la ventana donde se ven los mnesajes
  const [currentEmoji, setCurrentEmoji] = useState(null);
  const [isOpenEmoji, setIsOpenEmoji] = useState(false);
  const [website, setWebsite] = useState({});
  const refInput = useRef(null);
  const texts = {
    connected: translate('my_chats_section.online'),
    joined: translate('my_chats_section.interacctions_with_conversation.you_joined_chat'),
    unRead: translate('my_chats_section.unread_msg'),
    modalActionTransfer: translate('my_chats_section.transfer_to'),
    modalActionEndChat: translate('my_chats_section.end_chat'),
    modalBodyTitle: translate('my_chats_section.end_chat'),
    modalBodySubTitle: translate('my_chats_section.close_chat_question'),
    modalBodyParagraph: translate('my_chats_section.close_warning'),
    btnCancel: translate('my_chats_section.undo'),
    placeholderText: translate('my_chats_section.write_reply'),
  };

  useEffect(() => {
    if (messagesRef.current) {
      const win = messagesRef.current;
      win.scrollTo(0, win.scrollHeight);
    }
  }, [messages, isLoadingMessages]);

  useEffect(() => {
    const site = websites?.sites?.find((currentSite) => currentSite.id === selectedChat?.websiteId);
    setWebsite(site);
  }, [selectedChat]);

  const onEmojiClick = (_event, emojiObject) => {
    setCurrentEmoji(emojiObject.emoji);
    refInput.current.focus();
  };
  const getParticipantName = (participantId, options) => {
    const found = options.find((usert) => usert.id === participantId);
    return found?.name;
  };

  return (
    <ChatContainer>
      <ChatWindow>
        <ChatHeader
          modalActionTransfer={texts.modalActionTransfer}
          modalActionEndChat={texts.modalActionEndChat}
          modalBodyTitle={texts.modalBodyTitle}
          modalBodySubTitle={texts.modalBodySubTitle}
          modalBodyParagraph={texts.modalBodyParagraph}
          modalActionCancel={texts.btnCancel}
          chatbotName={selectedChat?.visitorName}
          callbackEndChat={handlerEndChat}
          callbackMobileBack={handlerMobileBack}
          time={time}
          channel={selectedChat?.channel}
          clientStatus={clientStatus}
          clientStatusColor={clientStatusColor}
          showActions={showActions}
          contactIcon={contactIcon}
          nroConversations={nroConversations}
          finishedConversation={selectedChat?.finishedConversation}
          selectedChat={selectedChat}
          tab={tab}
        />
        <MessagesWindow
          isOpenEmoji={isOpenEmoji}
          finishedConversation={selectedChat?.finishedConversation}
          activeFinishedMessage={featureLiveWarnignuserdisconnectedLive257}
        >
          <ChatboxBody ref={messagesRef}>
            {
              isLoadingMessages ? <ConversationMock loading />
                : (
                  <>
                    {!!initialDate
                      && <ConversationMeta color="#999">{initialDate}</ConversationMeta>}
                    {messages.map((e) => {
                      if (e.type === 'externalOperator') hasExternalOperator.current = true;
                      if (e.type === 'user') e.name = getParticipantName(e.sender, users) ?? 'Desconocido';
                      e.name = e.name !== 'Desconocido' ? e.name : `@${website?.title}`;
                      return <ConversationBubble {...e} sentAt={e.sendAt} key={e.id} channel={selectedChat?.channel} />;
                    })}
                    {selectedChat?.operatorFinishedConversation
                      && (
                        <ConversationMeta color={Colors.cliengoMain}>
                          {lastMessageDate}
                          <div>
                            {`${operatorName} ${translate('my_chats_section.interacctions_with_conversation.leave_chat')}`}
                          </div>
                        </ConversationMeta>
                      )}
                  </>
                )
            }
          </ChatboxBody>
        </MessagesWindow>

        <EmojiPicker
          isOpenEmoji={isOpenEmoji}
          onEmojiClick={onEmojiClick}
        />
        <ChatFooter
          isLoadingMessages={isLoadingMessages}
          whatsappPremium={selectedChat?.channel === 'WHATSAPP'}
          placeholderText={texts.placeholderText}
          callbackChatBox={handlerChatBoxPressed}
          conversationId={selectedChat?.id}
          currentEmoji={currentEmoji}
          setCurrentEmoji={setCurrentEmoji}
          isOpenEmoji={isOpenEmoji}
          setIsOpenEmoji={setIsOpenEmoji}
          refInput={refInput}
          finishedConversation={selectedChat?.finishedConversation}
          chatbotName={selectedChat?.visitorName}
          tab={tab}
          socketFunction={socketFunction}
          isExternalOperator={hasExternalOperator.current}
          websiteName={website?.title}
        />
      </ChatWindow>
    </ChatContainer>
  );
};

ChatConversation.defaultProps = {
  messages: [],
  callbackEndChat: () => false,
  showActions: true,
};

export default ChatConversation;
