import { SET_WEBSITES } from '../actions/types';

const initialState = {
  sites: [],
  hasAnyScriptInstalled: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_WEBSITES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
