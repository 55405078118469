import React from 'react';
import { TitleHeader } from './Title.styles';
import { setVisitoContextTooltip } from '../../../../Store/actions/visitorContext.actions';

export const Title = ({ children, tooltipContent }) => {
  const handleSetTooltip = (e) => {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    setVisitoContextTooltip({
      content: tooltipContent,
      position: {
        x: left,
        y: top - 25,
      },
    });
  };

  const handleResetTooltip = () => {
    setVisitoContextTooltip({
      content: null,
    });
  };

  return (
    <TitleHeader>
      {children}
      <i
        onMouseEnter={handleSetTooltip}
        onMouseOut={handleResetTooltip}
        className="icon icon-informacion"
      />
    </TitleHeader>
  );
};

export default Title;
