import styled from 'styled-components';

export const Tag = styled.div`
  border: solid 1px;
  padding: 5px 5px 3px 5px;
  border-radius: 4px;
  font-size: 10px;
  color: ${({ Color }: {Color: string}) => Color};
  border-color: ${({ Color }: {Color: string}) => Color};
`;

export const AlignedDate = styled.div`
  margin-left: 35px;
`;
