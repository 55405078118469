import styled from 'styled-components';

export const ChipStyled = styled.span`
  border: 1px solid #3B86FF;
  color: #3B86FF;
  padding: 4px 10px;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  border-radius: 999px;
  > .icon {
    font-size: 12px;
    justify-self: end;
    padding-left: 6px;
    vertical-align: middle;
  }
`;

export const ChipContainer = styled.div``;
