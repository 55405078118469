import styled from 'styled-components';

const Item = styled.div`
    background-color:#F7F7F7;
    border-bottom:1px solid #ddd;
    border-right:3px solid #F7F7F7;
    list-style: none;
    padding:10px 18px;
    position: relative;
    cursor: pointer;
    min-height: 33px;
    &:hover{
        background-color:#E8E8E8;
        border-right:3px solid #7D60F5;
    }
    &.selected {
        background-color:#E8E8E8;
        border-right:3px solid #7D60F5;
    }
    &.inactive,
    &.inactive:hover {
        background-color:#F7F7F7;
        border-right:3px solid #F7F7F7;
    }
    &.inactive {
        p,span,
        .icon-messenger,
        .icon-wa-solid,
        .icon-wa-solid:before,
        .icon-demographics,
        .icon-webicon {color:#808080}
    }
    @media (max-width: 480px) {
      background-color:#FFFFFF;
  }
`;

const NoChats = styled.div`
  text-align: center;
  font-size: 20px;
  color: gray;
  ${Item}{
  height: 60px;
  padding: 0;
  pointer-events: none;
   &:hover{
      background-color:#F7F7F7;
      border-right:none;
  }
  &:before{
    content: '';
    background: #EBEBEB;
    width: 45px;
    height: 10px;
    position: absolute;
    top: 15px;
    left: 18px;
    border-radius: 4px;
  }
  &:after{
    content: '';
    position: absolute;
    bottom: 15px;
    background: #EBEBEB;
    border-radius: 4px;
    width: 200px;
    height: 10px;
    left: 18px;
  }
  }
`;

export { Item, NoChats };
