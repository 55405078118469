import React from 'react';
import { ChipContainer } from '../../Components/Chip/Chip.styles';
import { Chip } from '../../Components/Chip/Chip.component';
import { Title } from '../../Components/Title/Title.component';
import { ModuleContainer, EmptyContainer } from '../../VisitorContext.styles';
import { getBotResponses } from '../../helpers/getBotResponses';
import { getVisitorName } from '../../helpers/getVisitorName';
import { translate } from '../../../../services/translate';

const SelectedOptions = () => {
  const options = getBotResponses();
  const visitorName = getVisitorName();

  return (
    <ModuleContainer>
      <Title tooltipContent={translate('visitor_context.bot_response_module.tooltip_content')}>
        {translate('visitor_context.bot_response_module.title')}
      </Title>
      {options?.length > 0 ? (
        <>
          <ChipContainer>
            {options.slice(0, 4).map((option) => (
              <Chip
                key={`option-${Math.random()}`}
                text={option}
              />
            ))}
          </ChipContainer>
        </>
      ) : (
        <EmptyContainer>
          {visitorName}
          {' '}
          {translate('visitor_context.bot_response_module.empty_state_section')}
        </EmptyContainer>
      )}
    </ModuleContainer>
  );
};

export default SelectedOptions;
