import styled from 'styled-components';

export const TitleHeader = styled.h6`
  display: flex;
  color: #51438F;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1rem;
  i {
    padding-left: 10px;
  }
`;

export default TitleHeader;
