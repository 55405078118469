import axios from 'axios';

import { getMessageStructure } from '../customHooks/getMessages';
import { Chats } from '../Interfaces/AllChats';
import { IUser } from '../Store/Authentication/Types';
import URLS from '../services/config';
import { MessageState } from '../Store/Message/Types';

import getMessageBody from './getMessageBody';

const getPreview = (chat: Chats, userName: string, message: string, date: Date): Chats['lastMessage'] | null => {
  const messageTimeStamp = date.getTime();
  const msgBody = message ?? '';

  if (chat.lastMessage?.date > messageTimeStamp) return null;
  return { message: `${userName}: ${msgBody}`, date: messageTimeStamp };
};

interface chatMessageType {
  data: {
    results: MessageState[]
  }
}

const getLastMessagePreview = async (chat: Chats, user: IUser) => {
  let lastMessageUpdated: Chats['lastMessage'] | null = null;
  try {
    const chatMessages: chatMessageType = await axios.get(
      `${URLS.urls.API_URL}/conversations/${chat.id}/messages`, { withCredentials: true },
    );
    if (chatMessages.data && chatMessages.data.results?.length > 0) {
      const records = chatMessages.data.results;
      const lastMessage = getMessageStructure(records[records.length - 1], user);
      const lastMessageBody = getMessageBody(lastMessage) as string;
      const { sendAt } = records[records.length - 1];
      const messageDate = sendAt ? new Date(sendAt) : new Date();

      lastMessageUpdated = getPreview(chat, lastMessage.name, lastMessageBody, messageDate);
    }
  } catch (error) {
    console.error(error);
  }

  return lastMessageUpdated;
};

export {
  getPreview,
  getLastMessagePreview,
};
