import React from 'react';
import classNames from 'classnames';

import { Item } from '../commonStyles';
import { translate } from '../../../../services/translate';
import NoChatsList from '../NoChats';

import ChatBadge from './styles';
import {
  Container,
  ChatItem,
  Icon,
  TitleItem,
  ParagraphItem,
  MessageTime,
} from './ChatsListmine.style';

const ChatsListMine = ({
  chatListMaxWidth,
  chatItems,
  handlerSelectedChat,
  selectedChat,
}) => (
  <Container width={chatListMaxWidth}>
    <ChatItem>
      {
        chatItems.length > 0 && (
          chatItems.map((chat) => (
            <Item
              key={chat.id}
              onClick={() => handlerSelectedChat(chat)}
              className={
                classNames({
                  selected: selectedChat === chat.id,
                })
              }
            >
              <Icon className={`icon-${chat.icon}`} />
              <TitleItem>{chat.title !== 'Cliente' ? chat.title : translate('client')}</TitleItem>
              <ParagraphItem>
                {chat.lastMessage?.message}
                {
                  chat.unRead
                  && <ChatBadge text="" fontSize="12px" />
                }
              </ParagraphItem>
              <MessageTime>
                {chat.lastMessageTime < 60 ? `${chat.lastMessageTime} min`
                  : `${parseInt(chat.lastMessageTime / 60, 10)} h`}
              </MessageTime>
            </Item>
          ))
        )
      }
      {
        chatItems.length === 0 && (
          <NoChatsList />
        )
      }

    </ChatItem>
  </Container>
);

export default ChatsListMine;
