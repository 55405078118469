import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDateTimePicker = ({
  onValue, onChangeValue, label, minDate, maxDate, classWrapper,
}) => {
  const newValue = onValue ? new Date(onValue) : '';
  return (
    <div className={`row is-flex-direction-column is-align-items-start is-full-width ${classWrapper || ''}`}>
      {label
        && <label>{label}</label>}
      <DatePicker
        selected={newValue}
        onChange={(value) => onChangeValue(value)}
        showTimeSelect
        minDate={minDate}
        placeholderText="dd/mm/aaaa"
        dateFormat="Pp"
        calendarIcon="calendar"
        maxDate={maxDate}
      />
      <div className="relative-icon">
        <i className="icon icon-cal" />
      </div>
    </div>
  );
};

export default CustomDateTimePicker;
