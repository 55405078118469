import styled, { css } from 'styled-components';
import { NavLink as Links } from 'react-router-dom';

import { Colors, headerHeight } from '../../../utils';
import { navHeight, navWidth } from '../../../utils/Styles.contrains';

interface PropsNavLinkContainer {
  show?: boolean;
  isLive?: boolean;
  isActive?: boolean;
}

export const Mask = styled.div`
  background: rgba(0, 0, 0, 0.3);
  opacity: ${({ show }: PropsNavLinkContainer) => (show ? '1' : '0')};
  visibility: ${({ show }: PropsNavLinkContainer) => (show ? 'visible' : 'hidden')};
  transition: opacity 350ms, visibility 350ms;
  width: 100%;
  height: 100%;
`;

export const NavLinkContainer = styled.div`
  visibility: ${({ show }: PropsNavLinkContainer) => (show ? 'visible' : 'hidden')};
  position: absolute;
  bottom: 0;
  width: ${navWidth};
  height: calc(100vh - ${headerHeight});
  z-index: 10;
  @media screen and (max-width: 768px) {
    width: 100vw;
    ${({ isLive }: PropsNavLinkContainer) => isLive && `height: ${navHeight}`};
    z-index: 2;
  }
`;
export const NavLinkContent = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateX(
    ${({ show }: PropsNavLinkContainer) => (show ? '0' : '-102')}%
  );
  visibility: ${({ show }: PropsNavLinkContainer) => (show ? 'visible' : 'hidden')};
  transition: transform 350ms, visibility 350ms;
  background-color: ${Colors.cliengoMainDarker};
  display: flex;
  overflow-y: hidden;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px;
  z-index: 11;
  @media screen and (max-width: 768px) {
    width: ${({ isLive }: PropsNavLinkContainer) => (isLive ? '100%' : '250px')};
    ${({ isLive }: PropsNavLinkContainer) => isLive
      && css`
        flex-direction: row;
        height: ${navHeight};
        align-items: stretch;
      `}
  }
`;
export const ChildrenContainer = styled.div`
  top: ${headerHeight};
  left: ${navWidth};
  height: calc(100vh - ${headerHeight});
  background: #f7f7f7;
  width: calc(100% - ${navWidth});
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    left: 0;
    width: 100%;
    ${({ isLive }: PropsNavLinkContainer) => isLive && `height: calc(100vh - ${headerHeight} - ${navHeight})`};
  }
`;
export const MenuItem = styled(Links)`
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.colorFontMenu};
  cursor: pointer;
  font-size: 10px;
  height: 60px;
  width: 100%;
  transition: all 0.2s ease;
  i {
    font-size: 20px;
    margin-bottom: 5px;
  }

  :hover,
  &.current {
    text-decoration: none;
    color: #fff;
    background-color: ${Colors.cliengoMainLighter};

    i {
      color: #fff;
      &.icon-candado {
        color: #d60000;
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: 'colum';
    font-size: 14px;
    justify-content: flex-start;
    padding-top: 8px;

    i {
      padding: 0 1rem;
      min-width: 30px;
    }
  }
`;
export const Button = styled.button`
  background: ${({ isActive }: PropsNavLinkContainer) => (isActive ? Colors.cliengoMainLighter : '0')};
  border: none;
  position: absolute;
  bottom: 0.3%;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.colorFontMenu};
  cursor: pointer;
  font-size: 10px;
  height: 60px;
  width: 100%;
  transition: all 0.2s ease;

  i {
    margin-bottom: 5px;
  }

  i:before {
    font-size: 20px;
  }

  svg {
    margin-bottom: 5px;
  }

  i:before {
    font-size: 20px;
  }

  :hover,
  &.current {
    text-decoration: none;
    color: #fff;
    background-color: ${Colors.cliengoMainLighter};

    svg {
      color: #fff;
    }
  }
  @media (max-width: 768px) {
    flex-direction: 'colum';
    font-size: 14px;
    justify-content: flex-start;
    position: initial;

    span {
      font-size: 14px;
    }

    i {
      padding: 0 1rem;
      min-width: 30px;
      padding-top: 6px;
    }

    svg {
      padding: 0 1rem;
      min-width: 30px;
      padding-top: 6px;
    }

    i:before {
      font-size: 20px;
    }
  }
`;
