import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { SearchSelect } from 'cliengo-ui';
import axios from 'axios';

import { translate } from '../../services/translate';
import { hasLabEcom } from '../../labs/hasLabEcom';
import config from '../../services/config';

const TagSelector = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const history = useHistory();
  const queryTag = query.get('tag'); // para ver si abre detalles o no
  const websiteId = query.get('web'); // para ver si abre detalles o no
  const [selectedTag, setSelectedTag] = useState(queryTag ?? '');
  const [tags, setTags] = useState([]);
  const [selectorTags, setSelectorTags] = useState([]);

  const emptyTags = [
    {
      value: '',
      label: '',
    },
  ];

  const changeTag = (selected) => {
    setSelectedTag(selected);
    if (selected !== '') {
      query.set('tag', selected);
    } else {
      query.delete('tag');
    }
    history.replace({ search: query.toString() });
  };

  const getTags = async () => {
    const response = await axios.get(`${config.urls.API_URL}/projects/allCompanyConversationTags`);
    if (response.status === 200) {
      const { data } = response;
      setTags(data);
    } else {
      console.info('error getting tags', { response });
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    let auxTags = [];

    if (websiteId) {
      auxTags = tags
        .filter((tag) => tag.website_id === websiteId)
        .map((tag) => tag.allConversationTags)
        .flat(Infinity);
    } else {
      auxTags = tags.map((tag) => tag.allConversationTags).flat(Infinity);
    }

    const websiteTags = [
      ...new Set(auxTags),
    ].map((tag) => ({ label: tag, value: tag }));
    websiteTags.unshift({
      label: translate('visitors_section.all_tags') || '',
      value: '',
    });
    setSelectorTags(websiteTags);
  }, [websiteId, tags]);

  if (!hasLabEcom()) return null;

  return (
    <SearchSelect
      options={hasLabEcom() && selectorTags.length > 0 ? selectorTags : emptyTags}
      placeholder={selectedTag ? selectedTag : translate('visitors_section.select_a_tag')}
      value={selectedTag}
      style={{ width: '380px', minWidth: '380px' }}
      onChange={(selected) => changeTag(selected)}
    />
  );
};

export default TagSelector;
