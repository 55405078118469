
const getOldChatItems = () => {
  const stringOldChat = localStorage.getItem('oldChatItems');

  return stringOldChat ? JSON.parse(stringOldChat) : [];
};
const setOldChatItems = (chatItems) => localStorage.setItem('oldChatItems', JSON.stringify(chatItems));

export {
  getOldChatItems,
  setOldChatItems,
}
