/* eslint-disable no-param-reassign */
/**
 * `executeOnlyOneTime` recives a function and ensures that the function will be executed one and just one time.
 * regardless of how many time it is been called. Optionally receives a context as second argument that serves as
 * the `this` for calling the function.
 * @param {Function} fn the function to be executed once.
 * @param {Object} [context=undefined] this context for the function call.
 * @returns {Function} function to be executed just once.
 */
export default function executeOnlyOneTime(fn, context) {
  let result;

  return function execute(...args) {
    if (fn) {
      result = fn.apply(context || this, args);
      fn = null;
      context = null;
    }

    return result;
  };
}
