// eslint-disable-next-line import/no-unresolved,
import { IContextTag } from '../Components/VisitorContext/Components/ContextTagContainer/ContextTagContainer.types';
import { LOCAL_TAGS_KEY } from '../Constants';

import { getLocalStorage } from './getLocalStorage';
import { setLocalStorage } from './setLocalStorage';

interface ILocalTags {
  [key: string]: IContextTag;
}

export const handleLocalTags = {
  get: ():any => getLocalStorage(LOCAL_TAGS_KEY),
  set: (tag:IContextTag):any => {
    const localTags = getLocalStorage(LOCAL_TAGS_KEY) as ILocalTags;
    setLocalStorage(LOCAL_TAGS_KEY, {
      ...localTags,
      [tag.conversationId]: tag,
    });
  },
};

export default handleLocalTags;
