import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import { Modal, Button, CircularLoader } from 'cliengo-ui';
import { translate } from '../../services/translate';
import analytics, { EVENTS } from '../../services/analytics';
import FileCard from './FileCard';
import FileError from './FileError';

import { Colors } from '../../utils';

const notAllowedTypesToSendFile = [
  'action', 'apk', 'app', 'bat', 'bin', 'cmd', 'com', 'command', 'cpl', 'csh', 'exe', 'gadget',
  'inf1', 'ins', 'inx', 'ipa', 'isu', 'job', 'jse', 'ksh', 'lnk', 'msc', 'msi', 'msp', 'mst',
  'osx', 'out', 'paf', 'pif', 'prg', 'ps1', 'reg', 'rgs', 'run', 'sct', 'shb', 'shs', 'torrent', 'u3p', 'vb',
  'vbe', 'vbs', 'vbscript', 'workflow', 'ws', 'wsf',
];

const FileSender = ({
  id = 'file-input',
  children,
  uploadCallback,
  maxSizeFile,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [convertingFile, setConvertingFile] = useState(false);
  const [captionAllowed, setCaptionAllowed] = useState(null);
  const [uploadingData, setUploadingData] = useState(false);
  const [errorDetected, setErrorDetected] = useState(null);
  const captionMessage = useRef(null);
  const onClickUpload = () => document.querySelector(`#${id}`).click();
  const toggleModal = () => setShowModal(!showModal);
  const admitsCaption = (file) => (file ? ['image', 'video'].includes(file.type) : false);
  const trackFileEvent = (event) => analytics.trackEvent(event);
  const closeModal = () => {
    toggleModal();
    setFiles([]);
    setCaptionAllowed(null);
    setConvertingFile(false);
    setUploadingData(false);
    setErrorDetected(null);
  };
  const extractExtension = (fileName) => {
    const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi;
    const match = fileName.match(fileExtensionPattern);
    return (match ? match[0].substring(1) : '');
  };
  const parseFileToUrl = async (fileTarget) => {
    const fileBase64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileTarget);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    return fileBase64;
  };
  const uploadFile = async (target) => {
    const currentFiles = files;
    if (!showModal) toggleModal();

    setConvertingFile(true);
    try {
      for (let i = 0; i < target.files.length; i++) {
        const currentFile = target.files[i];
        let [type, mime] = currentFile.type.split('/'); // eslint-disable-line prefer-const
        const extension = extractExtension(currentFile.name);

        if (['application', 'text'].includes(type)) type = 'file';
        if (captionAllowed === null) setCaptionAllowed(admitsCaption({ type }));
        if (notAllowedTypesToSendFile.includes(extension)) throw (new Error('{"notAllowedFormat": true}'));
        if (currentFile.size > maxSizeFile) throw (new Error('{"notAllowedSize": true}'));

        const fileUrl = await parseFileToUrl(currentFile); // eslint-disable-line no-await-in-loop
        currentFiles.push({
          url: fileUrl,
          type,
          name: currentFile.name,
          mimeType: currentFile.type,
          mime,
          extension,
        });
        const event = {
          name: EVENTS.ATTACH_FILE,
          category: 'live',
          label: extension,
        };
        trackFileEvent(event);
      }
    } catch (error) {
      setErrorDetected(JSON.parse(error.message));
    }

    setFiles(currentFiles);
    setConvertingFile(false);
    target.value = ''; // eslint-disable-line no-param-reassign
  };
  const generatePdfPreview = (fileUrl) => {
    const pageNumber = 1;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    return (
      <PdfWrapper>
        <Document
          file={fileUrl}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </PdfWrapper>
    );
  };
  const RenderFile = ({ file, converting }) => {
    if (!file) return (<></>);
    if (converting) return (<CircularLoader color={Colors.cliengoMain} />);

    const type = file.mime === 'pdf' ? 'pdf' : file.type;
    const fileRendered = {
      image: (<img src={file.url} alt={file.name} />),
      video: (
        <video controls>
          <source src={file.url} type={file.mimeType} />
          Your browser does not support the video tag.
        </video>
      ),
      audio: (
        <audio controls>
          <source src={file.url} type={file.mimeType} />
          Your browser does not support the audio tag.
        </audio>
      ),
      pdf: (generatePdfPreview(file)),
    };

    return fileRendered[type] || (
      <FileIcon>
        <i className="icon-file_copy" />
        <span>{file.name}</span>
      </FileIcon>
    );
  };
  const removeFile = (file) => {
    const filteredFiles = files.filter((currentFile) => currentFile.name !== file.name);

    setFiles(filteredFiles);
  };
  const sendFile = () => {
    let caption;
    const filesToSend = files.map((file) => {
      const captionedFile = { ...file };
      if (admitsCaption(file)) {
        captionedFile.caption = file.name;
      }

      return captionedFile;
    });

    if (uploadCallback) {
      setUploadingData(true);
      if (captionMessage?.current?.value) caption = { text: captionMessage.current.value, type: 'text/plain' };

      uploadCallback(filesToSend, caption)
        .then(() => {
          toggleModal();
          setUploadingData(false);
        })
        .catch((e) => {
          console.error('ha ocurrido un error', e);
          setUploadingData(false);
          setErrorDetected({ errorUploadingFiles: true });
        });
    }
    closeModal();
  };
  const onWritingMessage = (event) => {
    if (event.keyCode === 13) {
      sendFile();
    }
  };
  const addOthers = () => {
    setFiles([]);
    setCaptionAllowed(null);
    setErrorDetected(null);
  };

  return (
    <div>
      {
          children
            ? children(onClickUpload)
            : <Button callback={onClickUpload} title="Upload File" />
      }
      <input
        style={{ display: 'none' }}
        type="file"
        id={id}
        name={id}
        onChange={(e) => uploadFile(e.target)}
        multiple
      />
      <Modal
        visible={showModal}
        onClose={closeModal}
        closeMaskOnClick={false}
        animation="slideUp"
        customStyles={{ height: '70%', maxWidth: '565px', 'border-radius': '8px' }}
        autoFocus
      >
        <ModalHeader>
          <i className="icon-close" onClick={closeModal} />
        </ModalHeader>

        <ModalBody>
          {errorDetected
            ? (
              <FileError
                errorDetected={errorDetected}
                cancel={closeModal}
                addOthers={addOthers}
              />
            )
            : (
              <div>

                <FileWrapper>
                  <ContainerContent>
                    {convertingFile ? <CircularLoader color={Colors.cliengoMain} /> : <RenderFile file={files[0]} converting={convertingFile} />}
                  </ContainerContent>
                </FileWrapper>

                <AddFile>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    id="extra-file-loader"
                    name="extra-file-loader"
                    onChange={(e) => uploadFile(e.target)}
                    multiple
                  />
                  <i id="extra-file-loader" className="icon-add" onClick={onClickUpload} />
                </AddFile>
                <FilesInformationWrapper
                  displayPreview={files.length > 0}
                >
                  <CardsWrapper>
                    {files.length > 0
                      && files.map((file) => (
                        <FileCard
                          file={file}
                          removeElement={removeFile}
                        />
                      ))}
                  </CardsWrapper>
                </FilesInformationWrapper>
                <InputCaption>
                  <input
                    type="text"
                    placeholder={translate('my_chats_section.chat_multimedia.write_message')}
                    ref={captionMessage}
                    onKeyUp={onWritingMessage}
                    disabled={uploadingData}
                    autoFocus
                  />
                  {
                    convertingFile || uploadingData
                      ? <LoaderStyled color={Colors.cliengoMain} writeable={captionAllowed} />
                      : <i className="icon-send" onClick={sendFile} />
                  }
                </InputCaption>
              </div>
            )}

        </ModalBody>
      </Modal>
    </div>
  );
};

const LoaderStyled = styled(CircularLoader)`
  position: ${(props) => (props.writeable ? 'absolute' : '')};
  bottom: 0;
  right: 0;
  transform: scale(0.7);
`;

const InputCaption = styled.div`
  width: 100%;
  max-width: 80%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  height: 30px;
  display: flex;
  ${(props) => !props.writeable && css`
    display: flex;
    justify-content: center;
    i.icon-send{
      margin-left: 20px;
      font-size: 25px!important;
    }
  `}
  input{
    width: calc(100% - 65px);
    border: none;
    border-bottom: solid 1px #e0e0e0;
    transition: all 300ms;
    &:focus{
      outline: none;
      border-bottom-color: ${Colors.cliengoMain};
    }
    &:disabled{
      background: transparent;
      border-bottom-color: #e0e0e0;
    }
  }
  i.icon-send{
    position: ${(props) => (props.writeable ? 'absolute' : '')};
    bottom: 0;
    right: 0;
    color: ${Colors.cliengoMain};
    font-size: 30px;
    cursor: pointer;
  }
   @media (max-width: 480px), (max-height: 480px){
      bottom: 24px;
    }
`;

const ModalHeader = styled.div`
    width: 100%;
    height: 40px;
    background: ${Colors.cliengoMain};
    justify-content: flex-end;
    position: absolute;
    display: flex;
    transition: all 1s;
    top: 0;
    left: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 200;
    i{
      height: 3px
      font-size: 20px;
      color: #fff;
      position: absolute;
      top: 35%;
      right: 12px;
      transition: all 200ms;
      cursor: pointer;
      &:before{
          color: inherit!important;
      }
      &:hover{
          color: rgba(255,255,255,.7);
      }
    }
    @media (max-width: 480px), (max-height: 480px){
      height: 40px;
      i{
          font-size: 15px;
          top: calc(50% - (15px / 2));
          right: 18px;
      }
    }
`;

const ModalBody = styled.div`
  margin-top: 5%;
  text-align: center;
  display: list-item;
  vertical-align: middle;
  list-style-type: none;
`;

const AddFile = styled.div`
  display: inline-grid;
  position: absolute;
  margin-top: 8%;
  margin-left: -40%;
  i {
      display: inline-block;
      border-radius: 60px;
      padding: 0.5em 0.6em;
      border: solid
  }
  color: ${Colors.greenColor}
`;

const FileIcon = styled.div`
  position: relative;
  span{
    position: inherit;
    margin: 30px auto auto auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 15px;
    font-weight: bold;
    width: fit-content;
    height: fit-content;
  }
  i.icon-file_copy{
    display: block;
    font-size: 150px;
  }
`;

const ContainerContent = styled.div`
  width: 100%;
  margin-top: 10%;
  max-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FileWrapper = styled.div`
  max-height: 200px;
  width: 100%;
  margin-top: 10%;
  position: relative;
  i{
    color: ${Colors.cliengoMain};
    font-size: 50px;
    min-height: 200px;
  }
  img,
  embed,
  video{
    min-height: 200px;
    max-width: 85%;
  }
  audio{
    width: 80%;
    max-width: 660px;
    align-self: center;
    margin-top: 20%;
    height: 200px;
  }
`;

const PdfWrapper = styled.div`
  min-height: 200px;
  max-width: 85%;
  .react-pdf__Page__canvas{
    height: 200px !important;
    display: unset !important;
    max-width: 35%;
  }
  .react-pdf__Page__textContent{
    display:none;
  }
`;

const FilesInformationWrapper = styled.div`
  overflow-x: auto;
  display: flex;
  align-items: center;
  margin: 3% 20% 0px 20%;
  background: bisque;
  width: 70%;
`;

const CardsWrapper = styled.div`
  display: inline-flex;
  position: fixed;
  margin-top: 10%;
  overflow-x: auto;
  width: 70%;
  overflow-x: auto;
  height: 150px;
  align-items: end;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${Colors.cliengoMain};
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${Colors.cliengoMainLighter};
  }
`;

FileSender.propTypes = {
  id: PropTypes.string,
  children: PropTypes.func,
  uploadCallback: PropTypes.func,
  maxSizeFile: PropTypes.number,
};

FileSender.defaultProps = {
  id: 'file-sender-component',
  children: null,
  uploadCallback: () => { },
  maxSizeFile: 5e+8, //  50mb aprx
};

export default FileSender;
