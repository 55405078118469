import moment from 'moment';

import { channelIcons } from './states';

const getChatsStructure = (chat) => (
  (chat)
    ? {
      ...chat,
      title: chat.visitorName,
      initialContactDate: chat.creationDate,
      lastMessageDate: chat.lastMessageDate,
      lastMessageTime: moment().diff(moment(chat.lastMessageDate), 'minutes'),
      icon: channelIcons[chat.channel],
    }
    : null
);

export default getChatsStructure;
