import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { translate } from '../../../../services/translate';
import { CLOSE_SLIDE } from '../../../../Store/actions/types';

export const Header = () => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({ type: CLOSE_SLIDE });
  };
  return (
    <HeaderStyle>
      {translate('visitor_context.header.title')}
      <i
        onClick={handleClose}
        className="icon icon-close-panel"
      />
    </HeaderStyle>
  );
};

const HeaderStyle = styled.h6`
    color: rgb(81, 81, 81);
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    height: 55px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 18px;
    padding-left: 18px;
    border-bottom: 1px solid rgb(226, 226, 226);
    i {
      float: right;
      cursor: pointer;
      opacity: 0.7;
      font-size: 16px;
    }
`;

export default Header;
