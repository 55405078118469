import React from 'react';
import { useSelector } from 'react-redux';

import SelectedOptions from './Modules/SelectedOptions/SelectedOptions.component';
import { Header } from './Components/Header/Header.component';
import { VisitorQueries } from './Modules/VisitorQueries/VisitorQueries.component';
import { VisitorContextContainer } from './VisitorContext.styles';
import { GeneralInfo } from './Modules/GeneralInfo/GeneralInfo.component';
import { VisitorTooltip } from './Components/VisitorTooltip/VisitorTooltip.component';

export const VisitorContext = () => {
  const { slidePanel } = useSelector((state) => state);

  if (!slidePanel.open) return null;

  return (
    <>
      <VisitorContextContainer>
        <Header />
        <main>
          <GeneralInfo />
          <SelectedOptions />
          <VisitorQueries />
        </main>
      </VisitorContextContainer>
      <VisitorTooltip />
    </>
  );
};

export default VisitorContext;
