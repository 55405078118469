import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';

import store from './Store';
import App from './App';
import { GlobalStyles } from './GlobalStyles';
import config from './services/config';
import setAuthToken from './services/setAuthToken';

// const store = configureStore();
declare global {
  interface Window {
    hj: { q: string[] };
    _hjSettings: { hjid: string; hjsv: string };
    location: Location;
  }

}
Sentry.init({
  dsn: config.DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

(function connectHotjar() {
  if (process.env.REACT_APP_ENV !== 'production') return;
  window.hj = window.hj
    || function hj(args: string) {
      (window.hj.q = window.hj.q || []).push(args);
    };
  // eslint-disable-next-line no-underscore-dangle
  window._hjSettings = {
    hjid: process.env.REACT_APP_HOTJAR_ID ?? '',
    hjsv: process.env.REACT_APP_HOTJAR_SV ?? '',
  };
  const head = document.getElementsByTagName('head');
  const script = document.createElement('script');
  script.async = true;
  // eslint-disable-next-line no-underscore-dangle
  script.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
  head[0].appendChild(script);
}());

const authorizationGuard = () => { // eslint-disable-line consistent-return
  const jwt = Cookies.get('jwt');
  if (!jwt && process.env.NODE_ENV === 'development') {
    Cookies.set('jwt', process.env.REACT_APP_JWT || '');
    setAuthToken(process.env.REACT_APP_JWT);
  } else {
    if (jwt) return setAuthToken(jwt);
    const currentURL = window.location.href;
    window.location.href = `${config.urls.LOGIN}/?redirect=${currentURL}`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
(async () => {
  authorizationGuard();

  const {
    data: {
      id, name, email, companyId,
    },
  }: {
    data: { id: string; name: string; email: string; companyId: string; }
  } = await axios.get(`${config.urls.API_URL}/users/me`);
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchdarkly.key,
    user: {
      key: id,
      name,
      email,
      custom: {
        companyId,
      },
    },
  });

  ReactDOM.render(
    <LDProvider>
      <Provider store={store}>
        <GlobalStyles />
        <Router>
          <App />
        </Router>
      </Provider>
    </LDProvider>,
    document.getElementById('root'),
  );
})();
