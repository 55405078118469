import styled from 'styled-components';

export const ModalContainer = styled.div`
  > aside {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
`;
export const ModalBody = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 20px 2rem;
  width: 100%;
  max-width: 320px;
  opacity: 0;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.149));
  animation: fadeInDown .5s ease-in forwards;
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  main {
    display: flex;
    flex-direction: column;
    > button {
      background-color: transparent;
      border: none;
      margin-left: auto;
      display: block
      color: #808080;
      svg {
        color: #808080;
        font-size: 4rem;
        cursor: pointer;
      }
    }
    h3 {
      position: relative;
      margin: 0px;
      font-size: 20px;
    }
    p {
      margin: 1rem 0;
      font-size: 14px;
    }
    form {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      label {
        margin-left: 4px;
        margin-top: 4px;
      }
    }
  }
`;
const buttonBaseStyles = `
  border-radius: 99px;
  cursor: pointer;
  width: 114px;
  height: 32px;
  border-width: 1.5px;
  border-style: solid;
`;
export const ButtonGroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const CancelButton = styled.button`
  background-color: transparent;
  border-color: #808080;
  ${buttonBaseStyles}
`;
export const ContinueButton = styled.button`
  border-color: #7d60f5;
  background: #7d60f5;
  color: white;
  ${buttonBaseStyles}
`;
