declare global {
  interface ProcessEnv {
    REACT_APP_ENV: 'development' | 'production' | 'stage';
    CLOUDINARY_CLOUD_NAME: string;
    CLOUDINARY_API_KEY: string;
    CLOUDINARY_API_SECRET: string;
    CLOUDINARY_PRESET_CHATBOT: string;
    CLOUDINARY_PRESET_LIVE: string;
    CLOUDINARY_PRESET_PROFILE: string;
    LAUNCHDARKLY_KEY: string;
  }
}

const val: 'development' | 'production' | 'stage' | undefined = (
  process.env.REACT_APP_ENV === 'development'
  || process.env.REACT_APP_ENV === 'production'
  || process.env.REACT_APP_ENV === 'stage') ? process.env.REACT_APP_ENV : undefined;

const env: 'development' | 'production' | 'stage' = val || 'development';

const cloudinary: ICludinary = {
  cloudName: process.env.CLOUDINARY_CLOUD_NAME || 'hbrrdozyj',
  apiKey: process.env.CLOUDINARY_API_KEY || '652895756512659',
  apiSecret: process.env.CLOUDINARY_API_SECRET || 'eCS3DBW5317-cgRyDJlPy238q3s',
  uploadPresetForChatbot: process.env.CLOUDINARY_PRESET_CHATBOT || 'gkcwdhnu',
  uploadPresetForLive: process.env.CLOUDINARY_PRESET_LIVE || 'ejudv6gf',
  uploadPresetForProfile: process.env.CLOUDINARY_PRESET_PROFILE || 'sifpgseb',
};

const launchdarkly: ILaunchdarkly = {
  key: process.env.REACT_APP_LAUNCHDARKLY_KEY || '61a5074baed9da0fa6fc2c72',
};

interface ILaunchdarkly {
  key: string;
}
interface IUrl {
  LOGIN: string;
  API_URL: string;
  CORE_APP_URL: string;
  WEBOPTIMIZER_URL: string;
  SOCKETEER: string;
  LIVE: string;
  CRM: string;
  HISTORY: string;
  CONTEXT: string;
  LITTEN: string;
}

interface ICludinary {
  cloudName: string;
  apiKey: string;
  apiSecret: string;
  uploadPresetForChatbot: string;
  uploadPresetForLive: string;
  uploadPresetForProfile: string;
}

interface IConfig {
  development: {
    urls: IUrl;
    DSN: string,
    cloudinary: ICludinary;
    launchdarkly: ILaunchdarkly;
  };
  stage: {
    urls: IUrl;
    DSN: string,
    cloudinary: ICludinary;
    launchdarkly: ILaunchdarkly;
  };
  production: {
    urls: IUrl;
    DSN: string,
    cloudinary: ICludinary;
    launchdarkly: ILaunchdarkly;
  };
}

const config: IConfig = {
  development: {
    urls: {
      LOGIN: process.env.REACT_APP_LOGIN_PUBLIC_URL || 'https://www.login.stagecliengo.com',
      API_URL: process.env.REACT_APP_CLIENGO_API_PUBLIC_URL_1_0 || 'https://api.stagecliengo.com/1.0',
      CORE_APP_URL: process.env.REACT_APP_CORE_APP_URL || 'https://app.stagecliengo.com',
      WEBOPTIMIZER_URL: process.env.REACT_APP_WEBOPTIMIZER_URL || 'https://s.stagecliengo.com/weboptimizer',
      SOCKETEER: process.env.REACT_APP_SOCKETEER_OPERATORS_PUBLIC_URL || 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
      LIVE: process.env.REACT_APP_LIVE || 'localhost:3030/live',
      CRM: process.env.REACT_APP_CRM_PUBLIC_URL || 'https://crm.stagecliengo.com',
      HISTORY: process.env.REACT_APP_HISTORY || 'https://serverless.stagecliengo.com',
      CONTEXT: `${String(process.env.REACT_APP_CLIENGO_API_PUBLIC_URL_1_0)}/conversations/`,
      LITTEN: process.env.REACT_APP_LITTEN_URL || 'http://localhost:3003',
    },
    DSN: 'https://c69959431a4443d594f31b33c4b3afe5@o1133800.ingest.sentry.io/6180636',
    cloudinary,
    launchdarkly,
  },
  stage: {
    urls: {
      LOGIN: process.env.REACT_APP_LOGIN_PUBLIC_URL || 'https://www.login.stagecliengo.com',
      API_URL: process.env.REACT_APP_CLIENGO_API_PUBLIC_URL_1_0 || 'https://api.stagecliengo.com/1.0',
      CORE_APP_URL: process.env.REACT_APP_CORE_APP_URL || 'https://app.stagecliengo.com',
      WEBOPTIMIZER_URL: process.env.REACT_APP_WEBOPTIMIZER_URL || 'https://s.stagecliengo.com/weboptimizer',
      SOCKETEER: process.env.REACT_APP_SOCKETEER_OPERATORS_PUBLIC_URL || 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
      LIVE: process.env.REACT_APP_LIVE || 'https://live.stagecliengo.com/live',
      CRM: process.env.REACT_APP_CRM_PUBLIC_URL || 'https://crm.stagecliengo.com',
      HISTORY: process.env.REACT_APP_HISTORY || 'https://serverless.stagecliengo.com',
      CONTEXT: `${String(process.env.REACT_APP_CLIENGO_API_PUBLIC_URL_1_0)}/conversations/` || 'https://api.stagecliengo.com/1.0/conversations/',
      LITTEN: process.env.REACT_APP_LITTEN_URL || 'https://live2.stagecliengo.com',
    },
    DSN: 'https://c69959431a4443d594f31b33c4b3afe5@o1133800.ingest.sentry.io/6180636',
    cloudinary,
    launchdarkly,
  },
  production: {
    urls: {
      LOGIN: process.env.REACT_APP_LOGIN_PUBLIC_URL || 'https://www.login.cliengo.com',
      API_URL: process.env.REACT_APP_CLIENGO_API_PUBLIC_URL_1_0 || 'https://api.cliengo.com/1.0',
      CORE_APP_URL: process.env.REACT_APP_CORE_APP_URL || 'https://app.cliengo.com',
      WEBOPTIMIZER_URL: process.env.REACT_APP_WEBOPTIMIZER_URL || 'https://s.cliengo.com/weboptimizer',
      SOCKETEER: process.env.REACT_APP_SOCKETEER_OPERATORS_PUBLIC_URL || 'wss://socketeer.cliengo.com/operators',
      LIVE: process.env.REACT_APP_LIVE || 'https://live.cliengo.com/live',
      CRM: process.env.REACT_APP_CRM_PUBLIC_URL || 'https://crm.cliengo.com',
      HISTORY: process.env.REACT_APP_HISTORY || 'https://serverless.cliengo.com',
      CONTEXT: `${String(process.env.REACT_APP_CLIENGO_API_PUBLIC_URL_1_0)}/conversations/` || 'https://api.cliengo.com/1.0/conversations/',
      LITTEN: process.env.REACT_APP_LITTEN_URL || 'https://live2.cliengo.com',
    },
    DSN: 'https://c69959431a4443d594f31b33c4b3afe5@o1133800.ingest.sentry.io/6180636',
    cloudinary,
    launchdarkly,
  },
};

export default config[env];
