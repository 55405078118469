import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux';
import thunkMiddleware from 'redux-thunk';

import errorReducer from './reducers/errorReducer';
import navigationReducer from './reducers/navigationReducer';
import slideReducer from './reducers/slideReducer';
import chatsReducer from './reducers/chatInfoReducer';
import MessageReducer from './Message/Reducer';
import SortReducer from './Sort/Reducer';
import sitesReducer from './reducers/sitesReducer';
import liveUsersReducer from './reducers/liveUsersReducer';
import getLanguage from './reducers/langReducer';
import { AuthReducer } from './Authentication/Reducer';
import visitorReducer from './reducers/visitorContext.reducer';

const initialState = {};
const RootReducers = combineReducers({
  errors: errorReducer,
  auth: AuthReducer,
  navigation: navigationReducer,
  slidePanel: slideReducer,
  chatInfo: chatsReducer,
  message: MessageReducer,
  sort: SortReducer,
  websites: sitesReducer,
  users: liveUsersReducer,
  visitorContext: visitorReducer,
  getLanguage,
});

export type AppState = ReturnType<typeof RootReducers>;

export function configureStore() {
  const middlewares = [thunkMiddleware];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  || compose;
  const middleWareEnhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(
    RootReducers,
    initialState,
    middleWareEnhancer,
  );

  return store;
}

const store = configureStore();

export default store;
