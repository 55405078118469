import { SET_APP_SECTION } from './types';

const setAppSection = (decoded) => ({
  type: SET_APP_SECTION,
  payload: decoded,
});

const saveAppSection = (section) => async (dispatch) => {
  dispatch(setAppSection(section));
};

export default saveAppSection;

export { saveAppSection };
