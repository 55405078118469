import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

import { ContactDetailContainer } from '../Panels/ContactDetails';

import { VisitorContext } from './VisitorContext.component';

export const VisitorContextFeatureSelector = () => {
  const { hasLabSendConversationContext } = useSelector((state) => state.visitorContext);
  const { featureLitVisitorContextEcom333 } = useFlags();

  if (featureLitVisitorContextEcom333 && hasLabSendConversationContext) {
    return <VisitorContext />;
  }
  return <ContactDetailContainer />;
};

export function FeatureSelector() {

}
