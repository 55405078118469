import React from 'react';

import { Item, NoChats } from './commonStyles';

const NoChatsList = () => (
  <NoChats>
    {
      [...Array(5)].map(() => <Item key={Math.random()} />)
    }
  </NoChats>
);

export default NoChatsList;
