import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { closeSlide } from '../../../Store/actions/slide';
import { translate } from '../../../services/translate';
import { channelIcons } from '../../../utils/states';
import ContactDetailsView from './ContactDetail.component';

const ContactDetailHistoryContainer = (
  {
    closeSlide, convDataRaw, data, web,
  },
) => {
  const [detailsInfo, setDetailsInfo] = useState(null);
  useEffect(() => {
    const findUserSelected = convDataRaw?.find((info) => info?.id === data?.id);
    if (findUserSelected) {
      const findSiteByUser = web.find((site) => site?.id === findUserSelected?.website.id);
      const dataStructure = [{
        title: translate('visitors_section.contact_details.general_info'),
        info: [
          {
            value: findUserSelected.visitor.name,
            icon: 'icon-profile-solid',
          },
          {
            value: findUserSelected.visitor.phone_number,
            icon: 'icon icon-phone2',
            link: `tel:${findUserSelected.visitor.phone_number}`,
          },
          {
            value: findUserSelected.visitor.email_address,
            icon: 'icon icon-mail2',
            link: `mailTo:${findUserSelected.visitor.email_address}`,
          },
          {
            value: findUserSelected.location,
            icon: 'icon icon-location1',
          },
          {
            value: findUserSelected.channel.toLowerCase(),
            icon: `icon icon-${channelIcons[findUserSelected.channel]
              ? channelIcons[findUserSelected.channel] : 'livecliengo'}`,
          },
        ],
      },
      {
        title: translate('visitors_section.contact_details.browser_information'),
        info: [
          {
            title: translate('visitors_section.contact_details.site'),
            value: findSiteByUser?.title,
          },
          {
            title: translate('visitors_section.contact_details.source'),
            value: findSiteByUser?.url,
            link: findSiteByUser?.url,
          },
        ],
      }];
      setDetailsInfo(dataStructure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convDataRaw, data?.id]);

  return (<ContactDetailsView closeSlide={closeSlide} dataToShow={detailsInfo} />);
};

const mapStateToProps = (state) => ({
  data: state.slidePanel.content,
  web: state.websites.sites,
});

export default connect(mapStateToProps, { closeSlide })(ContactDetailHistoryContainer);
