// TODO: add permissions
const links = {
  live: [
    {
      icon: 'icon-my-chats',
      text: 'Mis Chats',
      link: '/live/active',
      key: 'my_chats_section.my_chats',
    },
    {
      icon: 'icon-visitors',
      text: 'Visitantes',
      link: '/live/visitors',
      key: 'visitors_section.visitors',
    },
    {
      icon: 'icon-live-history',
      text: 'Historial',
      link: '/live/history',
      key: 'history_section.history',
    },
  ],
};

export default links;
