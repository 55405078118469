const Colors = {
  cliengoMain: '#7D60F5',
  successColor: '#4DC06A',
  colorFontGeneral: '#515151',
  contentBackgroundColor: '#F2F2F2',
  blueLink: '#1855DB',
  blueLinkLight: '#3B86FF',
  cliengoMainDarker: '#51438F',
  cliengoMainLighter: '#7868C3',
  colorFontMenu: '#DEDEDE',
  grayIcon: '#808080',
  lightGray: '#E2E2E2',
  greenColor: '#1ABC9C',
  gold: '#FFA621',
  colorBackgroundSidebar: '#F7F7F7',
  colorBackgroundWhite: '#FFFFFF',
  colorTopNav: '#737373',
  colorRedDarker: '#B1346E',
  danger: '#EB2963',
  gray: '#a9a9a9',
  successColorDarker: '#18AB8E',
  colorOfLine: '#B3B3B3',
  finishedWarning: '#B82551',
  primaryGreenDark: '#14A085',
  primaryGreenDarker: '#0F8C6F',
  tabHeaderHover: '#9C9C9C',
};

export type IColors = typeof Colors

export default Colors;
