import React from 'react';

import { TitleChatWindow } from '../../../GlobalStyles';
import {
  MainContainerPanel, SubcontainerPanel, ChatHeaderPanel, ChatBodyPanel,
} from '../../../GlobalStyles/Layouts';
import { translate } from '../../../services/translate';
import Tooltip from '../../Tooltip/Tooltip';
import capitalize from '../../../utils/capitalize';

import {
  Icon, Subtitle, Spanpadding, Block, EmptyContainer, Square, Link,
} from './ContactDetails.styles';

const EmptyState = () => {
  const containerWidths = [10, 20, 15, 8, 30, 50, 20, 25, 40, 44, 55];
  return (
    <EmptyContainer>
      { containerWidths.map((w) => <Square width={w} key={Math.random()} />) }
    </EmptyContainer>
  );
};

const hasProtocol = (url = '') => (['http://', 'https://'].some((protocol) => (url.startsWith(protocol))));
const formatSiteString = (site) => {
  const isUrl = (siteValue = '') => (
    ['http://', 'https://', 'www.', '.com'].some((urlSegment) => (siteValue.includes(urlSegment)))
  );

  return isUrl(site) ? site : capitalize(site);
};

const ContactDetailsView = ({
  dataToShow,
  closeSlide,
}) => {
  const ContentPanel = ({ data }) => {
    const AdditionalInfoRender = ({ data }) => data.info.filter((info) => !!info.value).map((inf) => (
      <Spanpadding className="detail-site" key={inf.title + inf.value}>
        {!!inf.icon && <Icon className={inf.icon} />}
        {!!inf.title && <strong>{`${inf.title}:`}</strong>}
        {
          inf.link
            ? (
              <Tooltip
                direction="top"
                content={hasProtocol(inf.link) ? inf.link : `http://${inf.link}`}
                tooltipWidth="auto"
                alignmentX="-50%"
              >
                <Link>
                  <a
                    href={hasProtocol(inf.link) ? inf.link : `http://${inf.link}`}
                    target="_blank"
                    className="Link-panel"
                    rel="noopener noreferrer"
                  >
                    {formatSiteString(inf.value)}
                  </a>
                </Link>
              </Tooltip>
            )
            : formatSiteString(inf.value)
        }
      </Spanpadding>
    ));

    return (
      <ChatBodyPanel>
        {
        data.map((e) => (
          <Block key={e.title}>
            <Subtitle>{e.title}</Subtitle>
            <AdditionalInfoRender data={e} />
          </Block>
        ))
      }
      </ChatBodyPanel>
    );
  };

  return (
    <MainContainerPanel>
      <SubcontainerPanel>
        <ChatHeaderPanel>
          <TitleChatWindow data-testid="contact-details-title-window">
            {translate('visitors_section.contact_details.contact_details')}
          </TitleChatWindow>
          <Icon className="icon icon-close-panel" onClick={closeSlide} />
        </ChatHeaderPanel>
        {
          dataToShow
            ? <ContentPanel data={dataToShow} />
            : <EmptyState />
        }
      </SubcontainerPanel>
    </MainContainerPanel>
  );
};

// export default connect(mapStateToProps, { closeSlide })(ContactDetailsView);
export default ContactDetailsView;
