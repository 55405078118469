import React from 'react';
import { ChipStyled } from './Chip.styles';

export const Chip = ({ text, iconName }) => (
  <ChipStyled>
    {text}
    {iconName ? <i className={`icon icon-${iconName}`} /> : null}
  </ChipStyled>
);

export default Chip;
