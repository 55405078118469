import styled from 'styled-components';

import { SimpleNavLink } from '../../../GlobalStyles';
import { Colors, headerHeight } from '../../../utils';

export const Logo = styled(SimpleNavLink)`
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: inline-block;
    height: 100%;
    text-decoration: none;
    vertical-align: middle;
    width: 130px;
    margin-left: -40px;
    background-image: url('https://res.cloudinary.com/hbrrdozyj/image/upload/v1558546804/cliengo819x195_zxegqs.png');
`;

export const Wrapper = styled.header`
position: fixed;
width: 100%;
height: ${headerHeight};
z-index: 1001;
top:0;
`;

export const TopBar = styled.nav`
align-items: center;
background-color: ${Colors.colorBackgroundWhite};
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
border: 0;
display: flex;
flex-wrap: nowrap;
height: 100%;
justify-content: space-between;
white-space: nowrap;
`;

export const TopLeft = styled.div`
display: flex;
align-content: center;
justify-content: space-between;
height: 100%;
`;

export const TopRight = styled.div`
border: 0;
display: flex;
flex-wrap: nowrap;
height: 55px;
white-space: nowrap;
z-index: 100;
align-items: center;
justify-content: center;
transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
  z-index 0s linear 0.01s;

.return-to-crm{
  margin-right: 10px
}
@media only screen and (max-width: 768px) {
  display: none;
  .return-to-crm {
    height: 28px;
  }
}
`;

export const NavLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    width: 212px;
    .hamburgerButton{
    display: none;
    }
`;
