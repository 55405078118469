import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { SendFile } from 'cliengo-ui';
import FileSender from '../FileSender/FileSender';
import { translate } from '../../services/translate';

const ChatInput = ({
  text,
  setText,
  placeholderText,
  onKeyPress,
  refInput,
  whatsappPremium,
  onAttachment,
  translationsForSendFile,
  notAllowedTypesToSendFile,
  trackAttachFileEvent,
  isOpenEmoji,
  clickEmojiAction,
  sendMessage,
}) => {
  const { featureLiveWhatsappmultiattachLive260 } = useFlags();
  const getFileAttacher = () => {
    let jsxResponse;

    if (featureLiveWhatsappmultiattachLive260) {
      jsxResponse = (
        <FileSender
          id="file-input"
          uploadCallback={onAttachment}
          translations={translationsForSendFile}
          trackEvent={trackAttachFileEvent}
          maxSizeFile={6e+7}
        >
          {(onClick) => <span className="icon icon-atachment attachment-message-button" onClick={onClick} />}
        </FileSender>
      );
    } else {
      jsxResponse = (
        <SendFile
          id="file-input"
          uploadCallback={onAttachment}
          translations={translationsForSendFile}
          extensionNotSupported={notAllowedTypesToSendFile}
          trackEvent={trackAttachFileEvent}
          maxSizeFile={6e+7}
        >
          {(onClick) => <span className="icon icon-atachment attachment-message-button" onClick={onClick} />}
        </SendFile>
      );
    }

    return jsxResponse;
  };
  return (
    <div className="chat-input-container">
      <input
        type="text"
        placeholder={placeholderText}
        onChange={(ev) => setText(ev.target.value)}
        value={text}
        onKeyUp={onKeyPress}
        ref={refInput}
      />
      <div className="box-message-btn">
        {
            whatsappPremium
            && getFileAttacher()
        }

        <span
          title={translate('my_chats_section.pick_emoji')}
          className={`icon ${isOpenEmoji ? 'icon-close' : 'icon-emoticons'} emojis-button-insert`}
          onClick={clickEmojiAction}
        />
        <span
          title={translate('my_chats_section.button_send')}
          className="icon icon-paper-plane-outline send-message-button"
          onClick={sendMessage}
        />
      </div>
    </div>
  );
};

export default ChatInput;
