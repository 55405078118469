import { hasLabEcom } from '../../labs/hasLabEcom';
import { getLocalStorage } from '../../utils';

const filterByTag = (rows, tag) => {
  if (hasLabEcom() && tag !== '') {
    const localTags = getLocalStorage('cliengo_local_tags');
    const conversationsWithSelectedTag = Object.keys(localTags)
      .map((key) => localTags[key])
      ?.filter((tagObj) => tagObj?.conversationTag === tag.toUpperCase())
      .map((tagObj) => tagObj.conversationId);
    return rows.filter((row) => conversationsWithSelectedTag.includes(row.id));
  }
  return rows;
};

export default filterByTag;
