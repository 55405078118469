import React from 'react';
import { InfoElementContainer } from './InfoListElement.styles';

export const InfoListElement = ({
  iconName, keyName, content, href,
}) => (
  <InfoElementContainer>
    {iconName && <i className={`icon icon-${iconName}`} />}
    {keyName && (
    <b>
      {keyName}
      :
      {' '}
    </b>
    )}
    {content && href ? (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    ) : (
      <span>{content}</span>
    )}
  </InfoElementContainer>
);

export default InfoListElement;
