import { SET_LANG } from './types';

const setLang = (lang) => ({
  type: SET_LANG,
  payload: lang,
});

const getLang = (lang) => async (dispatch) => {
  dispatch(setLang(lang));
};

export default getLang;

export { getLang };
