import store from '../Store';
import { IData } from '../Store/Authentication/Types';

export const hasLabEcom = (): boolean => {
  const { auth } = store.getState();
  const { account, flags } = auth as unknown as IData;
  const isEcom = !!account.labs.ecommerce;
  const shouldShowOnLead = new Date(account.creationDate) > new Date('2022-10-28');
  if (isEcom || (shouldShowOnLead && flags.flagShouldShowTags)) {
    return true;
  }
  return false;
};

export default hasLabEcom;
