import React from 'react';
import styled, { css } from 'styled-components';

const mockConvers = [
  [{ width: 133 }],
  [{ width: 270, height: 73 }, { width: 213 }],
  [{ width: 133 }, { width: 213 }],
  [{ width: 213 }],
  [{ width: 133 }],
  [{ width: 213 }],
  [{ width: 270, height: 73 }, { width: 213 }],
];

const ConversationMock = ({ isLoading }) => (
  <>
    { !isLoading && <Header /> }
    <div style={{ padding: '0 12px' }}>
      {
          mockConvers.map((e, i) => (
            <BubbleContainer right={i % 2 === 0} isLoading={isLoading} key={Math.random()}>
              <BubbleName />
              {e.map((w) => <BubbleContent bWidth={w.width} bHeight={w.height} key={Math.random()} />)}
            </BubbleContainer>
          ))
        }
    </div>
  </>
);

const SkeletonLoading = css`
  background: linear-gradient(-90deg, #F0F0F0 0%, #EBEBEB 50%, #F0F0F0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -135% 0;
    }
  }
`;

const BubbleContent = styled.div`
  background: #EBEBEB;
  width: ${(props) => props.bWidth}px;
  height: ${(props) => props.bHeight || 35}px;;
  margin-bottom: 6px;

`;

const BubbleName = styled.div`
  background: #EBEBEB;
  height: 10px;
  width: 45px;
  border-radius: 6px;
  margin-bottom: 6px;

`;

const BubbleContainer = styled.div`
  width: fit-content;
  margin-top: 24px;
  ${(props) => (props.right ? 'margin-right: auto' : 'margin-left: auto')};
    ${BubbleName}{
      ${(props) => (props.right ? 'margin-right: auto' : 'margin-left: auto')};
      ${(p) => p.isLoading && SkeletonLoading}
    }
    ${BubbleContent}{
      ${(props) => (props.right ? 'margin-right: auto' : 'margin-left: auto')};
      ${(p) => p.isLloading && SkeletonLoading};
      border-radius: ${(props) => (props.right ? '0px 18px 18px 18px' : '18px 0px 18px 18px')};
    }
`;

const Header = styled.div`
    height: 55px;
    background: white;
    box-shadow: 0 0 2px #00000029;
    margin-bottom: 16px;
`;
export default ConversationMock;
