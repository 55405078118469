import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { saveAppSection } from '../../../Store/actions/navigation';

const TopMenu = (props) => {
  const { section } = props;
  return (
    <TopMenuWrapper isLive={section === 'live'}>
      <p>Live</p>
    </TopMenuWrapper>
  );
};

TopMenu.propTypes = {
  section: PropTypes.string,
};
TopMenu.defaultProps = {
  section: '',
};

const TopMenuWrapper = styled.ul`
  display: flex;
  list-style: none;
  margin: 0 0 0 10px;
  padding: 0;
  p {
    align-self: center;
    font: 29px/39px Nunito;
    font-weight: 600;
    color: #515151;
  }
  .badge {
    align-self: center;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 768px) {
    p {
      margin-right: 15px;
    }
  }
`;

const mapStateToProps = (state) => ({
  auth: state.auth,
  section: state.navigation.section,
  slidePanel: state.slidePanel,
  chatInfo: state.chatInfo,
  siteId: state.websites?.sites[0]?.id,
});
export default connect(mapStateToProps, { saveAppSection })(TopMenu);
