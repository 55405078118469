import React from 'react';
import TagStyles from './tag-css';

const TagLabel = ({ label, callBack }) => (
  <p className="row" style={TagStyles.tags}>
    {' '}
    {label}
    {' '}
    <button type="button" onClick={() => callBack()} style={TagStyles.buttonTag}>X</button>
    {' '}
  </p>
);

export default TagLabel;
