import styled from 'styled-components';

const ChatBadge = styled.span`
background: #E44338;
border-radius: 20px;
color: #fff;
font-size: 10px;
margin: 0;
padding: 0;
width: 12px !important;
height: 12px !important;
position: absolute;
right: 8%;
top: 30px;
min-height: 12px;
min-width: 12px;
`;

export default ChatBadge;
