/* eslint-disable import/prefer-default-export */
import { SET_CURRENT_USER } from '../actions/types';

import { LOGOUT, State } from './Types';

const initialStores = {
  isAuthenticated: false,
  account: {},
  user: {},
};

export const AuthReducer = (
  state = initialStores,
  action: {payload: State; type: string},
) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case LOGOUT:
      return initialStores;
    default:
      return state;
  }
};
