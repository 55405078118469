import styled from 'styled-components';
import { TextInput } from 'cliengo-ui';

import { Colors } from '../../utils';

const EmptyStateButton = styled.button`
  background-color: rgba(0,0,0,0) !important;
  border: none;
  color: #7d60f5;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
`;

const TextInputLive = styled(TextInput)`
  width: 100% !important;
`;

const MainContainer = styled.div`
  flex: 1, .userName {
    text-transform: capitalize;
  }
`;

const LeadStatus = styled.span`
  color: ${({ statusColor }: {statusColor: string}) => statusColor};
  i {
    font-size: 10px;
  }
`;

const BtnShowChat = styled.div`
  padding: 8px 18px;
  border: 1px solid ${Colors.primaryGreenDark};
  background: ${Colors.primaryGreenDark};
  color: white;
  cursor: pointer;
  float: left;
  border-radius: 4px;
  &:hover {
    background: ${Colors.primaryGreenDarker};
  }
  &:active {
    background: ${Colors.primaryGreenDark};
    color: white;
  }
`;

const LeadChannel = styled.span`
  text-transform: capitalize;
  i {
    &.icon-demographics,
    &.icon-public {
      color: #7d60f5;
    }
    &.icon-whatsapp,
    &.icon-wa-solid {
      color: #4dc06a;
    }
    &.icon-messenger {
      color: #2196f3;
    }
    &.icon-instagram {
      color: #7d60f5;
    }
  }
`;

const StyledSearchSelect = styled.div`
  display: flex;
  margin: 18px 0;
  flex-direction: row;
  justify-content: space-between;
  .selectSie {
    display: flex;
    align-items: center;
  }
  .icon-expand-more {
    top: 9px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    #select-search {
      height: 42px;
      display: flex;
      align-items: center;
    }
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 760px;
  margin: 18px 0;
  margin-right: 150px;
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }
`;

const SearchWrapperInput = styled.div`
  width: 30%;
  flex-direction: row;
  display: flex;
  background: white;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid rgb(221, 230, 233);
  height: 25px;
  align-self: center;
  div:nth-child(2) {
    display: flex;
    align-self: center;
    width: 100%;
  }
  .icon-search1 {
    margin-left: 6px;
    align-self: center;
    margin-right: 6px;
    font-size: 14px;
  }
  .conversation-search-input {
    border: none;
    padding: 0px;
    height: 26px;
  }
  .conversation-search-input:focus-visible {
    outline: none;
  }

  @media (max-width: 767px) {
    width: 97.4%;
    height: 30px;
    input.conversation-search-input {
      width: 505px;
    }
  }
`;

const ContentTitleTab = styled.div`
  min-width: 148px;
`;

export {
  MainContainer,
  LeadStatus,
  BtnShowChat,
  LeadChannel,
  StyledSearchSelect,
  ContentTitleTab,
  SearchWrapper,
  SearchWrapperInput,
  EmptyStateButton,
  TextInputLive,
};
