import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Container404, Icon404 } from './Page404.styles';

const Page404 = () => (
  <Container404>
    <Icon404 className="icon icon-chatbot-error" />
    <h1>404</h1>
    <h2>Página no encontrada</h2>
    <Link to="/live">Volver al inicio</Link>
  </Container404>
);

export default withRouter(Page404);
