import { Colors } from '../../utils';

const TagStyles = {
  tags: {
    color: Colors.blueLinkLight,
    borderWidth: 1,
    borderColor: Colors.blueLinkLight,
    borderStyle: 'solid',
    margin: '5px',
    padding: '5px',
    borderRadius: 5,
  },
  buttonTag: {
    border: 'none',
    color: Colors.blueLinkLight,
    cursor: 'pointer',
  },
};

export default TagStyles;
