import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Title } from '../../Components/Title/Title.component';
import { ModuleContainer } from '../../VisitorContext.styles';
import { InfoListElement } from '../../Components/InfoListElement/InfoListElement.component';
import { translate } from '../../../../services/translate';

export const GeneralInfo = () => {
  const [iconNameSocial, setIconNameSocial] = useState(null);
  const { generalInfo } = useSelector((state) => state.visitorContext);

  const findIconChannel = (channel) => {
    switch (channel) {
      case 'whatsapp':
        setIconNameSocial('whatsapp');
        break;
      case 'facebook':
        setIconNameSocial('facebook');
        break;
      case 'instagram':
        setIconNameSocial('instagram');
        break;
      default:
        setIconNameSocial('demographics');
        break;
    }
  };

  useEffect(() => {
    if (generalInfo.visitorChannel) {
      findIconChannel(generalInfo.visitorChannel);
    }
  }, [iconNameSocial, generalInfo]);

  return (
    <ModuleContainer>
      <Title tooltipContent={translate('visitor_context.general_information.tooltip_content')}>
        {translate('visitor_context.general_information.title')}
      </Title>
      {generalInfo.visitorName && <InfoListElement iconName="profile-solid" content={generalInfo.visitorName} />}
      {generalInfo.visitorPhone && <InfoListElement iconName="phone2" content={generalInfo.visitorPhone} href={`tel:${generalInfo.visitorPhone}`} />}
      {generalInfo.visitorEmail && <InfoListElement iconName="email" content={generalInfo.visitorEmail} href={`mailto:${generalInfo.visitorEmail}`} />}
      {generalInfo.visitorLocation && <InfoListElement iconName="location" content={generalInfo.visitorLocation} />}
      {generalInfo.visitorChannel && <InfoListElement iconName={iconNameSocial} content={generalInfo.visitorChannel} />}
      {generalInfo.operatorAssigned && <InfoListElement iconName="pregunta" content={generalInfo.operatorAssigned} />}
    </ModuleContainer>
  );
};

export default GeneralInfo;
