import styled from 'styled-components';
import { Colors } from '../../../utils';

const Icon = styled.i`
  color: ${Colors.grayIcon};
  font-size: 16px;
  cursor: pointer;
`;

const ContactTexting = styled.p`
  text-align: left;
  font-weight: bold;
  margin: 0 0 0 45px;
  width: 100%;
  text-indent: -26px;
  i{
    color: ${Colors.gold};
    margin-right: 10px;
  }
  .userName{
      text-transform: capitalize;
  }
`;

const ChatBodyContainer=styled.div`
  ${(props)=> props.isExternalOperator? 'height:600px;':'height: 100%'}
`
const WarningContainer = styled.div`
  margin-top: 20%;
  margin-left: 2%;
`
const ConversationMeta = styled.div`
  color: ${(props) => props.color || Colors.cliengoMain};
  width: 100%;
  position: relative;
  margin: 10px 0;
  text-align: center;
  font-size: 12px;
  p{
    font-size: 12px;
    margin: 0;
    display: inline;
  }
  &:before{ left: 0; }
  &:after{ right: 0; }
  &:before,
  &:after{
    content: '';
    position: absolute;
    width: calc(50% - 95px);
    bottom: 50%;
    height: 100%;
    border-bottom: 1px solid ${(props) => props.color || Colors.cliengoMain};;
  }
`;

export {
  Icon,
  ContactTexting,
  ChatBodyContainer,
  WarningContainer,
  ConversationMeta,
};
