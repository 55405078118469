/** CUSTOM HOOK PARA TRAER LAS CONVERSACIONES DE LA API DE MESSAGES */

import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';

import config from '../services/config';
import { translate } from '../services/translate';
import { IUser } from '../Store/Authentication/Types';
import { MessageState } from '../Store/Message/Types';
import { MessageType } from '../Interfaces/AllChats';

interface ResponseMessage {
  data: {
    results: MessageState[]
  }
}

interface CaseObject {
  [index: string]: string | undefined;
}
// Para extraer el nombre del usuario que envia el mensaje
const getMessageAuthor = (sender: MessageState['sender'],
  user: IUser, name?: string): string => {
  let ret = '';
  if (sender) {
    const cases: CaseObject = {
      robot: 'Robot',
      visitor: name || translate('client'),
      user: user.id === sender.id
        ? user.name
        : sender.name,
    };
    ret = cases[sender.type] || translate('unknown');
  }

  return ret;
};

// para formar la extructura de mensajes compatible con la interfaz
export const getMessageStructure = (e: MessageState, user: IUser): MessageType => ({
  name: getMessageAuthor(e.sender, user),
  msg: e.body,
  sendAt: moment(e.sendAt).format('DD/MM/YYYY hh:mm A'),
  msgType: e.type ?? 'text/plain',
  msgContent: e.message,
  type: e?.sender?.type as string,
  id: e.id,
  sender: e.sender?.id,
  quickReplies: e?.response_options || undefined,
});

const useGetMessages = (converId: string | null, user: IUser) => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (converId) {
      setIsLoading(true);
      let results: MessageType[];
      axios.get(
        `${config.urls.API_URL}/conversations/${converId}/messages`,
        { withCredentials: true },
      ).then((response: ResponseMessage) => {
        results = response.data?.results.map((e: MessageState) => getMessageStructure(e, user));
        if (results.length > 0) {
          setMessages(results);
        }
      }).catch((err) => {
        setMessages([]);
        console.error('Ocurrio un error al traer los mensajes ', err);
      }).finally(() => setIsLoading(false));
    }
  }, [converId, user]);

  return { messages, setMessages, isLoading };
};

export {
  useGetMessages,
};
