import styled, { css } from 'styled-components';

import { Colors, headerHeight } from '../../utils';

const generalHeight = '54px';

interface IProps {
  withFooter?: boolean;
  isOpenSlide?: boolean;
}
const MainContainerPanel = styled.div`
  height: 100%;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) -3px 0px 10px;
  @media (max-width: 767px) {
    width: 100vw;
  }
`;

const SubcontainerPanel = styled.div`
  height: 100%;
  background: white;
`;

const ChatHeaderPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${headerHeight};
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 18px;
  padding-left: 18px;
  border-bottom: solid 1px ${Colors.lightGray};
  z-index: 2;
`;

const ChatBodyPanel = styled.div`
  padding-right: 18px;
  padding-left: 18px;
  height: ${({ withFooter }: IProps) => (withFooter
    ? `calc(100% - (${generalHeight} * 2))`
    : `calc(100% - ${generalHeight})`)};
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  background: #ffffff;
  top: ${generalHeight};
`;
const ChatFooterPanel = styled.div`
  position: absolute;
  bottom: 0;
  height: ${generalHeight};
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  box-shadow: 0px -1px 12px #0000000f;
  .button-converse {
    background: ${Colors.greenColor}!important;
    transition: background 100ms;
    &:disabled {
      background: ${Colors.gray}!important;
    }
    &:focus {
      outline: none;
    }
  }
`;

const NewSlideContainer = styled.div`
  width: 25%;
  min-width: 320px;
  height: 100%;
  position: absolute;
  z-index: 1000;
  top: 0;
  right: -27%;
  visibility: hidden;
  transition: all 400ms;
  ${({ isOpenSlide }: IProps) => isOpenSlide
    && css`
      visibility: visible !important;
      right: 0 !important;
    `};
  @media (max-width: 767px) {
    width: 100%;
    right: -102%;
  }
`;

export {
  MainContainerPanel,
  SubcontainerPanel,
  ChatHeaderPanel,
  ChatBodyPanel,
  ChatFooterPanel,
  NewSlideContainer,
};
