import store from '..';

import { SET_SORT, RESET_SORT, SortState } from './Types';

export const setSort = (sortState: SortState) => {
  store.dispatch({
    type: SET_SORT,
    payload: sortState,
  });
};

export const resetSort = () => ({
  type: RESET_SORT,
});
