/* eslint-disable */
import React from 'react';

import { translate } from '../../../../services/translate';

// eslint-disable-next-line import/no-unresolved
import { IModalProps } from './Modal.types';
import {
  ModalContainer, ModalBody, ButtonGroupButton, CancelButton, ContinueButton,
} from './Modal.styles';

export const Modal = ({
  show,
  onClose,
  onDontShowAgain,
  onIntervention,
  showWarningDialog,
}: IModalProps) => {
  if (!show) return null;

  return (
    <ModalContainer>
      <aside role="button" onClick={onClose}>
        <ModalBody>
          <main>
            <button type="button" role="button" onClick={onClose} id="close_modal">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="currentColor"
                />
              </svg>
            </button>
            <h3>{translate('visitors_section.chat_preview.enter_chat_msg')}</h3>
            <p>
              {translate('visitors_section.chat_preview.inactive_robot_msg')}
            </p>
            <form onClick={(e) => e.stopPropagation()}>
              <input
                value={String(showWarningDialog)}
                onChange={onDontShowAgain}
                id="remind"
                type="checkbox"
              />
              <label htmlFor="remind">
                {translate('visitors_section.chat_preview.dont_show_again')}
              </label>
            </form>
          </main>
          <footer>
            <ButtonGroupButton>
              <CancelButton onClick={onClose}>
                {translate('visitors_section.chat_preview.cancel')}
              </CancelButton>
              <ContinueButton onClick={onIntervention}>
                {translate('visitors_section.chat_preview.continue')}
              </ContinueButton>
            </ButtonGroupButton>
          </footer>
        </ModalBody>
      </aside>
    </ModalContainer>
  );
};

export default Modal;
