import { SET_SORT, RESET_SORT, SortState } from './Types';

const initialState: SortState = {
  key: 'lastContact',
  value: 'desc',
};

export default (state = initialState, action: { type: string, payload: SortState }) => {
  switch (action.type) {
    case SET_SORT:
      return { ...action.payload };
    case RESET_SORT:
      return initialState;
    default:
      return state;
  }
};
