import React from 'react';

import { Colors } from '../../../../utils';

import { TagContainer } from './TagButton.styles';
import { ITagButton } from './TagButton.types.d';

export const TagButton = ({ text, color, handler }: ITagButton) => (
  <TagContainer
    color={Colors[color] || '#3B86FF'}
    onClick={handler}
  >
    {text}
  </TagContainer>
);

export default TagButton;
