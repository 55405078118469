import store from '../../../Store';

/* eslint-disable import/prefer-default-export */
export const getBotResponses = () => {
  const { visitorContext } = store.getState();
  const { websiteId, selectedOption, selectedConversation } = visitorContext;
  if (websiteId) {
    if (selectedOption[selectedConversation]) {
      return [...selectedOption[selectedConversation].data].reverse().slice(0, 4);
    }
  }
  return null;
};
