import styled, { createGlobalStyle } from 'styled-components';

import { Colors } from '../utils';

const GlobalStyles = createGlobalStyle`
  body{
    color: ${Colors.colorFontGeneral};
    font-family: Nunito,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 14px;
    .containerModal{
      bottom: 0;
      right: 0;
      left: initial!important;
      top: initial!important;
      height: calc(100% - 55px);
      width: calc(25% - 15px);
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
`;

const TitleChatWindow = styled.h6`
  color: ${Colors.colorFontGeneral};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

export { GlobalStyles, TitleChatWindow };
