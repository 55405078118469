import styled from 'styled-components';

import { Colors } from '../../../../utils';

const Container = styled.div`
    font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
    background-color: #F2F2F2;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: solid 1px #ddd;
    min-width: calc(${(props) => (props.width ? props.width : 'auto')} - 20px);
    @media (max-width: 480px) {
        width: 100%;
        max-width: 100%;
    }
`;

const ChatItem = styled.div`
    padding-left: 0;
    margin: 0;
    max-height: 58vh;
    overflow-x: hidden;
    width: 100%;
    background: #F7F7F7;
    @media (max-width: 520px) {
      width: 100%;
      max-width: 100%;
      max-height: 45vh;
    }

    @media (max-height: 480px) {
        max-height: 45vh;
    }

    @media (max-height: 920px) and (max-width: 480px) {
        max-height: 54vh;
    }
`;
const Icon = styled.i`
    width: 14px;
    height: 14px;
    margin-right: 7px;
    color: ${Colors.cliengoMain};
    &.icon-messenger {
        color: #2096f3;
    }
    &.icon-whatsapp {
        color: #4dc06a;
    }
    &.icon-livecliengo {
        color: #7d60f5;
    }
    &.icon-webicon {
        color: #7d60f5;
    }
`;
const TitleItem = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #515151;
    vertical-align: top;
    letter-spacing: 0;
    text-transform: capitalize;
`;
const ParagraphItem = styled.p`
    font-size: 12px;
    color: #515151;
    letter-spacing: 0;
    line-height: 1.4em;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E44338;
        border-radius: 8px;
        color: #fff;
        font-size: 10px;
        margin:0;
        padding: 0;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 18px;
        top: 30px;
    }
`;
const MessageTime = styled.span`
    font-size: 10px;
    color: #515151;
    position: absolute;
    top: 14px;
    right: 18px;
`;

export {
  Container,
  ChatItem,
  Icon,
  TitleItem,
  ParagraphItem,
  MessageTime,
};
