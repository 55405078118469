import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container404, Icon404 } from '../Page404/Page404.styles';

const GeneralError = ({ code }) => (
  <Container404>
    <Icon404 className="icon icon-chatbot-error" />
    <h1>{code || '401'}</h1>
    <h2>Ocurrio un error, intente mas tarde</h2>
  </Container404>
);

export default withRouter(GeneralError);
