import styled from "styled-components";
import { Colors } from "../../utils";

const InterveneConversationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background: #fff;
  align-items: center;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  Button{
    background: ${Colors.greenColor};
    margin-left: 35%;
  }
`
export {
  InterveneConversationContainer,
}
