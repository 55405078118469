import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Picker from 'emoji-picker-react';
import { translate } from '../../services/translate';

const EmojiPicker = ({
  isOpenEmoji,
  onEmojiClick,
}) => {
  const sectionsBlocked = {
    flags: false,
    food_drink: false,
  };
  const sectionNames = {
    smileys_people: translate('my_chats_section.emoji_section.smileys_people'),
    animals_nature: translate('my_chats_section.emoji_section.animals_nature'),
    travel_places: translate('my_chats_section.emoji_section.travel_places'),
    activities: translate('my_chats_section.emoji_section.activities'),
    objects: translate('my_chats_section.emoji_section.objects'),
    symbols: translate('my_chats_section.emoji_section.symbols'),
    recently_used: translate('my_chats_section.emoji_section.recently_used'),
  };
  const pickerStyles = {
    width: '100%',
    height: '200px',
  };

  return (
    isOpenEmoji
    && (
      <EmojiPickerContainer>
        <Picker
          onEmojiClick={onEmojiClick}
          pickerStyle={pickerStyles}
          disableSearchBar={true} // eslint-disable-line react/jsx-boolean-value
          groupVisibility={sectionsBlocked}
          groupNames={sectionNames}
        />
      </EmojiPickerContainer>
    )
  );
};

EmojiPicker.propTypes = {
  isOpenEmoji: PropTypes.bool,
  onEmojiClick: PropTypes.func,
};

EmojiPicker.defaultProps = {
  isOpenEmoji: false,
  onEmojiClick: () => { },
};

const EmojiPickerContainer = styled.div`
  width: 100%;
  height: 200px;
  `;

export default EmojiPicker;
