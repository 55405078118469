import React from 'react';
import { Button, Checkbox, Modal } from 'cliengo-ui';
import styled from 'styled-components';

import { translate } from '../../services/translate';

const ModalInterveneConversation = ({
  closeModal,
  showModal,
  sendConverseEvent,
  dontShowAgain,
  dontShowAgainCallback,
  idToInsertIn,
  containerClass,
  modalWidth,
}) => (
  <StyledModal
    id="ModalInterveneConversationStyleModal"
    onClose={closeModal}
    visible={showModal}
    idToInsertIn={idToInsertIn}
    containerClass={containerClass}
    bgMaskColor="rgb(0,0,0,0.7)"
    customCloseButton={<i className="icon-cerrar" />}
    width={modalWidth}
  >
    <h2>{translate('visitors_section.chat_preview.enter_chat_msg')}</h2>
    <p>{translate('visitors_section.chat_preview.inactive_robot_msg')}</p>
    <Checkbox
      classNames="check"
      label={translate('visitors_section.chat_preview.dont_show_again')}
      callback={(val) => dontShowAgainCallback(val)}
      value={dontShowAgain}
    />
    <div className="buttonContainer">
      <Button
        title={translate('visitors_section.chat_preview.cancel')}
        callback={closeModal}
        className="buttonContinue"
      />
      <Button
        title={translate('visitors_section.chat_preview.continue')}
        callback={sendConverseEvent}
        className="buttonCancel"
      />
    </div>
  </StyledModal>
);

const StyledModal = styled(Modal)`
  padding: 20px;
  width: ${(props) => props.width ?? '75%'};
  border-radius: 4px;
  .icon-cerrar {
    font-size: 24px;
    color: #a7a2a2;
    &:hover {
      color: #858282;
    }
    &:focus {
      outline: none;
    }
  }
  h2 {
    font-size: 18px;
  }
  .check {
    margin-bottom: 45px;
    font-size: 12px;
    margin-top: 25px;
    height: 20px;
    .ui-checkmark {
      height: 15px;
      width: 15px;
      border: solid gray 2px;
      &:after {
        top: -1px;
        left: 4px;
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    button:focus {
      outline: none;
    }
    .buttonContinue {
      background: white;
      color: #515151;
      border-color: #dcdbdb;
      min-width: 100px;
      box-shadow: 0 2px 10px #00000026;
      margin-right: 15px;
      &:hover {
        background: lightgray;
      }
    }
    .buttonCancel {
      min-width: 100px;
      box-shadow: 0 2px 10px #00000026;
      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export default ModalInterveneConversation;
