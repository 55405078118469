import store from '..';
import { Chats, StateAllChats, User } from '../../Interfaces/AllChats';
import { translate } from '../../services/translate';
import {
  getPreview, capitalize, getLocalStorage, setLocalStorage,
} from '../../utils';
import { updateChatInfo } from '../actions';
import { UPDATE_ONE_CHAT } from '../actions/types';

import {
  ADD_MESSAGE,
  CLEAR_MESSAGES,
  MessageState,
  propertiesType,
} from './Types';

interface CaseObject {
  [index: string]: string | undefined;
}
interface ParticipantObject {
    type: string,
    id: string
}

export const addMessage = (msg: MessageState) => (dispatch: (action: () => void | object) => void,
  getState: () => StateAllChats) => {
  const { chatInfo: { available, taken, mine }, users } = getState();
  let chat: Chats|null = null;
  let needsUnRead = false;

  if (available.length > 0) {
    chat = available?.find((currentChat: Chats) => currentChat.id === msg.conversationId) as Chats;
  }
  if (!chat && taken.length > 0) {
    chat = taken?.find((currentChat: Chats) => currentChat.id === msg.conversationId) as Chats;
    needsUnRead = chat !== undefined;
  }
  if (!chat && mine.length > 0) {
    chat = mine?.find((currentChat: Chats) => currentChat.id === msg.conversationId) as Chats;
    needsUnRead = chat !== undefined;
  }

  if (chat && msg.sender) {
    let user;
    const { type } = msg.sender;
    if (chat.participants && chat.participants.length > 0) {
      const sender = chat.participants.find((participant: ParticipantObject) => participant?.type === type);
      user = users.find((user: User) => user.id === sender?.id);
    }
    const cases: CaseObject = {
      robot: 'Robot',
      visitor: chat.visitorName || translate('client'),
      user: user?.name,
    };
    const name = cases[type] || translate('unknown');
    const date = msg.sendAt ? new Date(msg.sendAt) : new Date();
    const preview = getPreview(chat, capitalize(name), msg.body, date);

    const properties: propertiesType[] = [
      {
        field: 'lastMessage',
        value: preview,
      },
      {
        field: 'lastMessageDate',
        value: msg.sendAt,
      },
    ];
    if (needsUnRead) {
      const unReadStorage = getLocalStorage('unRead') as Array<string>;
      if (unReadStorage) {
        const unRead = unReadStorage.find((unread) => unread === msg.conversationId);

        if (!unRead && msg.conversationId) {
          unReadStorage.push(msg.conversationId);
          setLocalStorage('unRead', unReadStorage);
        }
      } else {
        setLocalStorage('unRead', [msg.conversationId]);
      }
      properties.push({
        field: 'unRead',
        value: true,
      });
    }

    dispatch(updateChatInfo({
      id: msg.conversationId,
      properties,
    }, user, UPDATE_ONE_CHAT));
  }
  store.dispatch({
    type: ADD_MESSAGE,
    payload: msg,
  });
};

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});
