import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import config from '../services/config';
import { setLocalStorage } from '../utils';

interface LittenMessageDataValuesParams {
  activeTab: string;
  conversationId: string;
}

interface LittenMessageDataValues {
  chatId: string;
  params: LittenMessageDataValuesParams;
}

interface LittenMessageData {
  id: string;
  action: string;
  values: LittenMessageDataValues;
}

interface LittenMessageEvent {
  data: LittenMessageData;
  origin: string;
}

interface HistoryParams {
  pathname: string;
  search?: string;
}

const useLittenMessages = (isUsingLitten = false) => {
  const history = useHistory();

  const manageLittenMessages = (event: LittenMessageEvent) => {
    if (
      event.origin === config.urls.LITTEN
      && event.data.id
      && event.data.id === 'litten'
      && event.data.action
    ) {
      switch (event.data.action) {
        case 'redirectToMyChats':
          if (event.data.values?.chatId) {
            setLocalStorage('mine', event.data.values.chatId);
            history.push({
              pathname: '/live/active/mine',
              search: `?chatId=${event.data.values.chatId}`,
            });
          }
          break;
        case 'urlParamsChange':
          if (event.data.values?.params) {
            const newHistory: HistoryParams = {
              pathname: `/live/active/${
                event.data.values.params.activeTab === 'taken'
                  ? 'mine'
                  : 'available'
              }`,
            };

            if (event.data.values.params.conversationId) {
              newHistory.search = `?chatId=${event.data.values.params.conversationId}`;
            }

            history.push(newHistory);
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (isUsingLitten) {
      window.addEventListener('message', manageLittenMessages);
    }
    return () => {
      if (isUsingLitten) {
        window.removeEventListener('message', manageLittenMessages);
      }
    };
  }, [isUsingLitten]);
};

export default useLittenMessages;
