import styled from 'styled-components';

import { Colors } from '../utils';

const Badge = styled.span`
  align-self: center;
  background: ${Colors.cliengoMain};
  border-radius: 5px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  min-width: 26px;
  padding: 0 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  vertical-align: middle;
  white-space: nowrap;
  display: inline-block;
`;

export default Badge;
