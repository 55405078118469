/* eslint-disable import/prefer-default-export */

import React from 'react';
import { Title } from '../../Components/Title/Title.component';
import { RelevantMessage } from '../../Components/RelevantMessage/RelevantMessage.component';
import { getVisitorQueries } from '../../helpers/getVisitorQueries';
import { ModuleContainer, EmptyContainer } from '../../VisitorContext.styles';
import { translate } from '../../../../services/translate';

export const VisitorQueries = () => {
  const queries = getVisitorQueries();

  return (
    <ModuleContainer>
      <Title
        tooltipContent={translate(
          'visitor_context.visitor_inquiries.tooltip_content',
        )}
      >
        {translate('visitor_context.visitor_inquiries.title')}
      </Title>
      {queries?.length > 0 ? (
        queries.map((query) => (
          <RelevantMessage content={query} key={`query-${Math.random()}`} />
        ))
      ) : (
        <EmptyContainer>
          {translate('visitor_context.visitor_inquiries.empty_state_section')}
        </EmptyContainer>
      )}
    </ModuleContainer>
  );
};
