import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Colors } from '../../../../utils';

const VisitorTooltipContainer = styled.span`
  position: fixed;
  z-index: 1010;
  background-color: ${Colors.colorFontGeneral};
  color: white;
  ${({ position, width }) => position && `
    top: ${position.y}px;
    left: ${position.x}px;
    width: ${width}px;
  `}
  transform: translate(-50%, -75%);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  &::after {
    content: "";
    position: absolute;
    left: 54%;
    bottom: -6px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: ${Colors.colorFontGeneral};
  }
`;

export const VisitorTooltip = () => {
  const { content, position } = useSelector((state) => state.visitorContext.tooltip);

  if (!content) return <></>;

  const calculateWidth = () => {
    const len = content.length * 7;
    // eslint-disable-next-line no-nested-ternary
    return len <= 175 ? 175 : len >= 280 ? 280 : len;
  };

  return (
    <VisitorTooltipContainer width={calculateWidth()} position={position}>
      {content}
    </VisitorTooltipContainer>
  );
};

export default VisitorTooltip;
