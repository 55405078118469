import styled from 'styled-components';

export const InfoElementContainer = styled.p`
    padding-bottom: .8rem;
    i {
       margin-right: 5px;
    }
    a {
        color: #3B86FF;
    }
`;

export default InfoElementContainer;
