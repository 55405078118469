import React, { useEffect, useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { saveAppSection } from '../../../Store/actions/navigation';
import menuLink from '../../../utils/menu-links';
import Topbar from '../../Navigation/Topbar/topbar';
import { translate } from '../../../services/translate';
import IconNotificationFulled from '../../../assets/icons/IconNotifications';
import { StateAllChats } from '../../../Interfaces/AllChats';
import { NavLinkModel, Props, Menu } from '../../../Interfaces/GlobalLayout';
import getMql from '../../../utils/mql';

import {
  Button,
  ChildrenContainer,
  Mask,
  MenuItem,
  NavLinkContainer,
  NavLinkContent,
} from './MainLayout';

const mql = getMql();

const LINKS: {live: Menu[]} = menuLink;

declare global {
  interface Window {
    OneSignal: {
      isPushNotificationsEnabled: () => Promise<boolean>;
      setSubscription: (val: boolean) => void;
      showNativePrompt: () => void;
      push: (val: () => void) => void;
    };
  }
}

interface LinkObject {
    icon: string;
    text: string;
    link: string;
    key: string;
}

const NewLayout = ({ children, section }: Props) => {
  const location = useLocation();
  const history = useHistory();

  const isLive: boolean = section === 'live';
  const account = useSelector((state: StateAllChats) => state.auth?.account);
  const visibleHistory = account.features.available.LIVE_CONVERSATION_HISTORY
    || account.labs.history_conversations;
  const [isDeviceMobile, setIsDeviceMobile] = useState(mql.matches);
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const { OneSignal } = window;

  /*
   * condiciones para mostrat el menu
   * que este en live, bien sea mobile o no
   * que no este en live y que este en mobile
   *
   * condiciones para ocultarlo
   * no en live y en mobile
   * */
  const mediaQueryChanged = (): void => {
    setIsDeviceMobile(mql.matches);
  };

  const [showMenu, setShowMenu] = useState(isLive || !isDeviceMobile);

  const updateCurrentSection = useCallback(() => {
    const sections = Object.keys(LINKS);

    sections.map((key: string) => {
      const currentLink = LINKS.live.find(
        (l: Menu) => l.link === location.pathname,
      );

      if (currentLink) {
        // console.debug(`found ${currentLink.link} within ${key}`);
        if (section !== key) {
          saveAppSection(key);
        }
      }
      // If current link found, stop iterating!
      return !!currentLink;
    });
  }, [location.pathname, history, section]);

  const openMenu = () => setShowMenu(!showMenu);
  const toggleNotifications = () => {
    if (isEnable) {
      OneSignal.setSubscription(isEnable);
    } else {
      OneSignal.push(() => {
        OneSignal.showNativePrompt();
      });
      OneSignal.setSubscription(true);
    }
    setIsEnable(!isEnable);
  };

  useEffect(() => {
    setShowMenu(isLive || !isDeviceMobile);

    OneSignal.isPushNotificationsEnabled()
      .then((isEnabled: boolean) => {
        setIsEnable(isEnabled);
      }).catch((error: string) => console.error(error));
  }, [isLive, isDeviceMobile, OneSignal]);

  useEffect(() => {
    mql.addEventListener('change', mediaQueryChanged);
    updateCurrentSection();
    return () => mql.removeEventListener('change', mediaQueryChanged); // Clear the listener when the component is to be unmounted
  }, [updateCurrentSection]);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateCurrentSection();
  }, [updateCurrentSection]);

  return (
    <>
      <Topbar
        isLive={isLive}
        hamburgerHandler={openMenu}
        isOpenHamburger={isLive ? false : showMenu}
      />
      <NavLink isLive={isLive} show={showMenu} openMenu={openMenu}>
        <>
          {menuLink.live.map(
            (linkObj: LinkObject) => {
              const isVisibleSection = linkObj.key !== 'history_section.history' || visibleHistory;

              return !isVisibleSection ? null : (
                <MenuItem
                  exact
                  activeClassName="current"
                  to={linkObj.link}
                  key={linkObj.link}
                >
                  <i className={linkObj.icon} />
                  {linkObj.key && <span>{translate(linkObj.key)}</span>}
                </MenuItem>
              );
            },
          )}
          <Button onClick={toggleNotifications} isActive={isEnable}>
            {isEnable ? (
              <IconNotificationFulled />
            ) : (
              <img
                width="20"
                height="25"
                src="https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/alarm-off.svg"
                alt="off-notification"
              />
            )}
            <span>{translate('notifications_menu')}</span>
          </Button>
        </>
      </NavLink>
      <ChildrenContainer isLive>{children}</ChildrenContainer>
    </>
  );
};

const NavLink = ({
  isLive, children, show, openMenu,
}: NavLinkModel) => (
  <NavLinkContainer isLive={isLive} show={show}>
    <NavLinkContent isLive={isLive} show={show}>
      {children}
    </NavLinkContent>
    <Mask onClick={openMenu} show={show} />
  </NavLinkContainer>
);

const mapStateToProps = (state: StateAllChats) => ({
  section: state.navigation.section,
  slidePanel: state.slidePanel,
  auth: state.auth,
});

export default connect(mapStateToProps, { saveAppSection })(NewLayout);
