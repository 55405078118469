import { SET_APP_SLIDE, OPEN_SLIDE, CLOSE_SLIDE } from './types';

const setSlide = (decoded, action) => ({
  type: action,
  payload: decoded,
});

export const saveSlide = (slide, action = SET_APP_SLIDE) => async (dispatch) => {
  dispatch(setSlide(slide, action));
};

export const closeSlide = () => async (dispatch) => dispatch({ type: CLOSE_SLIDE });
export const openSlide = () => async (dispatch) => dispatch({ type: OPEN_SLIDE });
