import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../utils/colors';
import { translate } from '../../services/translate';
import capitalize from '../../utils/capitalize';

const WarningMessage = ({
  user,
  isFinishedConversation,
  isExternalOperator,
  websiteName,
  height,
}) => {
  const shouldRender = isFinishedConversation || isExternalOperator;
  let message;

  if(isFinishedConversation){
    message = `${capitalize(user !== 'Cliente' ? user : translate('client'))}
      ${translate('my_chats_section.interacctions_with_conversation.finished_conversation')}`;
  }

  if(isExternalOperator){
    message = `${translate('visitors_section.warning_message.chat_intervened')}
    ${websiteName}${translate('visitors_section.warning_message.chat_disclaimer')}`;
  }

  return(
  shouldRender
  && (
    <MessageContainer height={height}>
      <p>
        <span className="icon icon-feedback redIcon" />
        <span className="finished-message">
          {message}
        </span>
      </p>
    </MessageContainer>
  )
)};

WarningMessage.propTypes = {
  user: PropTypes.string,
  isFinishedConversation: PropTypes.bool,
  isExternalOperator: PropTypes.bool,
  websiteName: PropTypes.string,
  height: PropTypes.string,
};

WarningMessage.defaultProps = {
  user: '',
  isFinishedConversation: false,
  isExternalOperator: false,
  websiteName: '',
  height: '41px',
};

const MessageContainer = styled.span`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: ${({ height }) => height};
    align-items: start;
    margin-bottom: 10px;
    background: #fff;
    align-items: center;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .redIcon{
      color: ${colors.finishedWarning};
    }
  .finished-message {
      margin-left: inherit;
      color: ${colors.colorFontGeneral};
  }
  `;

export default WarningMessage;
