import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { closeSlide } from '../../../Store/actions/slide';
import { translate } from '../../../services/translate';
import { channelIcons } from '../../../utils/states';

import ContactDetailsView from './ContactDetail.component';

const useQuery = () => new URLSearchParams(useLocation().search);

const ContactDetailContainer = ({
  data,
  closeSlide,
  chatInfo,
  sitesStore,
  users,
  oldChats,
}) => {
  const [dataToShow, setDataToShow] = useState(null);
  const { featureLiveWarnignuserdisconnectedLive257 } = useFlags();
  const history = useHistory();
  const baseUrl = useLocation().pathname;
  const query = useQuery();
  useEffect(() => {
    const allConversationStore = [...chatInfo?.available, ...chatInfo?.taken];
    if (oldChats && featureLiveWarnignuserdisconnectedLive257)allConversationStore.push(...oldChats);
    const findUserSelected = allConversationStore.find((info) => data?.id === info?.id);
    if (findUserSelected) {
      const findSiteByUser = sitesStore.find((site) => site.id === findUserSelected.websiteId);
      const userAssigned = findUserSelected.participants?.find((e) => e.type === 'user');
      const userResult = users.find((itm) => itm.id === userAssigned?.id);

      setDataToShow([{
        title: translate('visitors_section.contact_details.general_info'),
        info: [
          {
            value: findUserSelected.visitorName,
            icon: 'icon-profile-solid',
          },
          {
            value: findUserSelected.visitorPhone,
            icon: 'icon icon-phone2',
            link: `tel:${findUserSelected.visitorPhone}`,
          },
          {
            value: findUserSelected.visitorEmail,
            icon: 'icon icon-mail2',
            link: `mailTo:${findUserSelected.visitorEmail}`,
          },
          {
            value: findUserSelected.location,
            icon: 'icon icon-location1',
          },
          {
            value: findUserSelected.channel?.toLowerCase(),
            icon: `icon icon-${channelIcons[findUserSelected.channel]
              ? channelIcons[findUserSelected.channel] : 'livecliengo'}`,
          },
          {
            value: userResult?.name,
            icon: 'icon icon-assign',
          }],
      },
      {
        title: translate('visitors_section.contact_details.browser_information'),
        info: [
          {
            title: translate('visitors_section.contact_details.site'),
            value: findSiteByUser?.title,
          },
          {
            title: translate('visitors_section.contact_details.source'),
            value: findSiteByUser?.url,
            link: findSiteByUser?.url,
          },
        ],
      }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatInfo, data?.id]);

  return (
    <ContactDetailsView
      closeSlide={() => {
        if (!baseUrl.includes('/active')) {
          query.delete('chatId');
          history.replace({ search: query.toString() });
        }
        closeSlide();
      }}
      dataToShow={dataToShow}
    />
  );
};

const mapStateToProps = (state) => ({
  chatInfo: state.chatInfo,
  data: state.slidePanel.content,
  sitesStore: state.websites.sites,
  users: state.users,
});

export default connect(mapStateToProps, { closeSlide })(ContactDetailContainer);
