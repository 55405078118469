import React, { lazy, Suspense } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TitleChatWindow } from '../../../../GlobalStyles';
import {
  MainContainerPanel,
  SubcontainerPanel,
  ChatHeaderPanel,
} from '../../../../GlobalStyles/Layouts';
import { Icon } from '../ConversationDetails.styles';
import { closeSlide } from '../../../../Store/actions/slide';

const LittenConversationDetailsIframe = lazy(
  () => import('./LittenConversationDetailsIframe'),
);

const useQuery = () => new URLSearchParams(useLocation().search);

const ConversationDetailsLittenIframe = ({ ...props }) => {
  const dispatch = useDispatch();
  const queryChatId = useQuery().get('chatId');
  const history = useHistory();

  const sliderCloser = () => {
    dispatch(closeSlide());
    if (queryChatId) {
      history.replace({ search: '' });
    }
  };



  return (
    <MainContainerPanel className={props.className}>
      <SubcontainerPanel>
        <ChatHeaderPanel>
          <TitleChatWindow>Chat</TitleChatWindow>
          <Icon className="icon icon-close-panel" onClick={sliderCloser} />
        </ChatHeaderPanel>

        <div
          style={{ height: 'calc(100% - 54px)', position: 'relative', top: 54 }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <LittenConversationDetailsIframe />
          </Suspense>
        </div>
      </SubcontainerPanel>
    </MainContainerPanel>
  );
};

export default ConversationDetailsLittenIframe;
