import { useHistory } from 'react-router-dom';

const useGoToUrl = () => {
  const history = useHistory();
  return (url = '') => history.push(url);
};
export default useGoToUrl;
export {
  useGoToUrl,
};
