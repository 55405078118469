import { MessageState, ADD_MESSAGE, CLEAR_MESSAGES } from './Types';

const initialState: MessageState[] = [];

export default (state = initialState, action: { type: string, payload: MessageState }) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return [...state, action.payload];
    case CLEAR_MESSAGES:
      return initialState;
    default:
      return state;
  }
};
