export const setLocalStorage = (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    console.info('Cant set localstorage: ', error);
    return false;
  }
};

export default setLocalStorage;
