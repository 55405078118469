import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Colors from '../utils/colors';

const SimpleNavLink = styled(NavLink)`
  color: ${(props) => props.color || Colors.colorFontGeneral};
  text-decoration: none;
  :hover {
    color: ${(props) => props.color || Colors.colorFontGeneral};
    text-decoration: none;
  }
`;

export default SimpleNavLink;
