/* eslint-disable camelcase, no-underscore-dangle */
import axios from 'axios';
import moment from 'moment';

import { Chat } from '../Interfaces/History';

import config from './config';

interface PaginationResponse {
  data: {
    _metadata: {
      page: number,
      page_count: number;
      page_size: number;
      total_count: number;
    },
    data: Chat[]
  }
}

const getConversationHistory = async (
  page?: {page: number},
  query?: string,
) => {
  let concatQuery = '';
  const SevenDaysToDie = moment().subtract(7, 'days').toISOString();

  if (query) concatQuery = query;
  if (page && !query) concatQuery = `?from=${SevenDaysToDie}&page=${page.page}`;
  if (page && query) concatQuery += `&page=${page.page}`;

  concatQuery = (
    concatQuery === '' || concatQuery === '?'
  ) ? `?page_size=25&from=${SevenDaysToDie}` : `${concatQuery}&page_size=25`;

  const response: PaginationResponse = await axios.get(`${config.urls.HISTORY}/conversation/search${concatQuery}`);
  const {
    page_count,
    page_size,
    total_count,
  } = response.data._metadata;

  const paginationSettings = {
    page: response.data._metadata.page,
    page_count,
    page_size,
    total_count,
  };

  const { data } = response.data;

  return {
    paginationSettings,
    data,
  };
};

export default getConversationHistory;
