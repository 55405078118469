import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'cliengo-ui';
import { translate } from '../../services/translate';

import { Colors, helpfulLink } from '../../utils';

const FileError = ({
  errorDetected,
  cancel,
  addOthers,
}) => {
  const RenderErrorUploadingFiles = () => (
    <SendingMessageError>
      <i className="icon-error-file" />
      <span>{translate('my_chats_section.chat_multimedia.errors.type_not_allowed')}</span>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={translate('my_chats_section.chat_multimedia.type_extensions')}
      >
        {
          translate('my_chats_section.chat_multimedia.type_extensions_title')
        }
      </a>
    </SendingMessageError>
  );

  const RenderErrorNotAllowedFormat = () => (
    <NotAllowedError>
      <p>
        <img alt="img" src={helpfulLink.thereWasAnError} />
      </p>
      <p><b>{translate('my_chats_section.chat_multimedia.errors.load_file_error')}</b></p>
      <span>{translate('my_chats_section.chat_multimedia.file_format_error')}</span>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={helpfulLink.helpCenter}
      >
        {
          translate('my_chats_section.chat_multimedia.help_center')
        }
      </a>
      <ButtonContainer>
        <CancelButton
          title={translate('my_chats_section.chat_multimedia.cancel')}
          onClick={cancel}
        />
        <Button
          title={translate('my_chats_section.chat_multimedia.add_more')}
          onClick={addOthers}
        />
      </ButtonContainer>
    </NotAllowedError>
  );

  return (
    <ErrorMessageStyled>
      {errorDetected.errorUploadingFiles
        && RenderErrorUploadingFiles()}
      {(errorDetected.notAllowedSize || errorDetected.notAllowedFormat)
        && RenderErrorNotAllowedFormat()}
    </ErrorMessageStyled>
  );
};

const ErrorMessageStyled = styled.div`
  margin-top: 30%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const SendingMessageError = styled.div`
  padding: 35px 20px;
  width: 70%;
  border: 2px dashed ${Colors.colorRedDarker};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  .span{
    margin-top: 20px;
    color: ${Colors.colorRedDarker};
    font-size: 16px;
    font-weight: bold;
  }
  .icon-error-file{
    font-size: 60px;
    color: ${Colors.colorFontGeneral};
  }
  a{
    color: ${Colors.colorFontGeneral};
    font-size: 16px;
    margin-top: 16px;
    text-decoration: underline;
  }
`;

const NotAllowedError = styled.div`
  max-width: 70%;
  font-size: 14px;
  p b{
    font-size: 20px;
  }
 span{
    overflow: hidden;
    white-space: break-spaces;
    display: inline;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 7% 15%;
`;

const CancelButton = styled(Button)`
  background-color: ${Colors.colorBackgroundWhite};
  color: black;
  margin-right: 6%;
  border: 2px solid #808080;
`;

FileError.propTypes = {
  errorDetected: PropTypes.object,
  cancel: PropTypes.func,
  addOthers: PropTypes.func,
};

FileError.defaultProps = {
  errorDetected: {
    notAllowedFormat: false,
    notAllowedSize: false,
    errorUploadingFiles: false,
  },
  cancel: () => {},
  addOthers: () => {},
};

export default FileError;
