import { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { translate } from '../../services/translate';
import { Convs, StateAllChats, Table } from '../../Interfaces/AllChats';

import { sortByNamePropsTypes, ReturnSortType, SortHeaderType } from './AllChatsTypes';

type setStateType<T> = React.Dispatch<React.SetStateAction<T>>

const useSortingItems = (
  items: Convs[],
  setItems: setStateType<Convs[]>,
): ReturnSortType => {
  const account = useSelector((state: StateAllChats) => state.auth?.account);
  const [sortByChannelOrder, setSortByChannelOrder] = useState('ASC');
  const [sortByStatusOrder, setSortByStatusOrder] = useState('ONLINE');
  const [sortByVisitorNameOrder, setSortByVisitorNameOrder] = useState('ASC');
  const [sortByLastContactDateOrder, setSortByLastContactDateOrder] = useState('DESC');
  const [sortByOperatorNameOrder, setSortByOperatorNameOrder] = useState('ASC');
  const [sortByContitionOrder, setsortByContitionOrder] = useState('ASC');

  const lastContactHeader: SortHeaderType = {
    index: 0,
    mobile: true,
    orderMobile: 0,
    value: translate('visitors_section.general_table.last_message'),
    sort: true,
    show: true,
    key: 'lastContact',
  };
  const statusHeader: SortHeaderType = {
    index: 1,
    mobile: true,
    orderMobile: 1,
    value: translate('visitors_section.general_table.state'),
    sort: true,
    show: true,
    key: 'status',
  };
  const nameHeader: SortHeaderType = {
    index: 2,
    mobile: true,
    orderMobile: 2,
    value: translate('visitors_section.general_table.name'),
    sort: true,
    show: true,
    key: 'name',
  };
  const channelHeader: SortHeaderType = {
    index: 3,
    mobile: true,
    orderMobile: 3,
    value: translate('visitors_section.general_table.channel'),
    sort: true,
    show: true,
    key: 'channel',
  };
  const chattingWithHeader: SortHeaderType = {
    index: 4,
    mobile: true,
    orderMobile: 4,
    value: translate('visitors_section.general_table.assigned_to'),
    sort: true,
    show: true,
    key: 'chattingWith',
  };
  const actionsHeader: SortHeaderType = {
    index: 5,
    mobile: true,
    orderMobile: 5,
    value: translate('visitors_section.general_table.actions'),
    show: true,
    key: 'actions',
  };
  const conditionHeader: SortHeaderType = {
    index: 6,
    mobile: true,
    orderMobile: 6,
    value: translate('visitors_section.general_table.condition'),
    sort: true,
    show: !account.labs.ecommerce,
    key: 'condition',
  };
  const intervenedBy: SortHeaderType = {
    index: 7,
    mobile: true,
    orderMobile: 7,
    value: translate('visitors_section.general_table.intervened_by'),
    sort: true,
    show: true,
    key: 'intervened',
  };
  const tags: SortHeaderType = {
    index: 8,
    mobile: true,
    orderMobile: 8,
    value: translate('visitor_context.tags.title'),
    sort: false,
    show: true,
    key: 'tags',
  };

  // Sort by name, given the name, this is an auxiliary function,
  const sortByName = ({ key, order, setOrder }: sortByNamePropsTypes) => {
    const chatsOrderedByName = Array.from(items.sort(
      (prev: Convs, curr: Convs) => {
        const prevNameValue = prev?.table.find(
          (item: Table) => item.key === key,
        )?.value;
        const currNameValue = curr?.table.find(
          (item: Table) => item.key === key,
        )?.value;
        const translateClient = translate('client').toLowerCase().trim();
        const prevName = prevNameValue ? prevNameValue?.toLowerCase().trim() : translateClient;
        const CurrName = currNameValue ? currNameValue?.toLowerCase().trim() : translateClient;

        if (order === 'DESC') {
          setOrder('ASC');
          return prevName > CurrName ? 1 : -1;
        }

        setOrder('DESC');
        return CurrName > prevName ? 1 : -1;
      },
    ));
    setItems(chatsOrderedByName);
  };

  /** sort by `status`. Wheter is `ONLINE` or `AWAY` */
  const sortByStatus = (sort: string = sortByStatusOrder) => {
    const sorting = sort === 'desc' ? 'ONLINE' : 'AWAY';

    const chatsOrderedByStatus = Array.from(
      items.sort(
        (item1: Convs, item2: Convs) => {
          const prevStatusValue1 = item1?.table.find(
            (item: Table) => item.key === statusHeader.key,
          )?.value || '';
          const prevStatusValue2 = item2?.table.find(
            (item: Table) => item.key === statusHeader.key,
          )?.value || '';

          setSortByStatusOrder(sorting === 'ONLINE' ? 'AWAY' : 'ONLINE');
          if (prevStatusValue1 !== prevStatusValue2) {
            return prevStatusValue1 === sorting ? -1 : 1;
          }
          return 0;
        },
      ),
    );
    // console.log("chatsOrderedByStatus", chatsOrderedByStatus);
    setItems(chatsOrderedByStatus);
  };

  // sort by channel: Facebook, Web, Whatsapp, and Instagram (for the future)
  const sortByChannel = (sort: string = sortByChannelOrder) => {
    const chatsOrderedByChannel = Array.from(items.sort(
      (prev: Convs, curr: Convs) => {
        const prevChanelValue = prev?.table.find(
          (item: Table) => item.key === channelHeader.key,
        )?.value || '';
        const currChanelValue = curr?.table.find(
          (item: Table) => item.key === channelHeader.key,
        )?.value || '';
        const prevChannel = prevChanelValue?.toLowerCase();
        const CurrChannel = currChanelValue?.toLowerCase();

        if (sort.toLocaleUpperCase() === 'DESC') {
          setSortByChannelOrder('ASC');
          return prevChannel > CurrChannel ? 1 : -1;
        }

        setSortByChannelOrder('DESC');
        return CurrChannel > prevChannel ? 1 : -1;
      },
    ));
    setItems(chatsOrderedByChannel);
  };

  /** sort by `lastContact` date */
  const sortByLastContactDate = (sort:string = sortByLastContactDateOrder) => {
    const chatsOrderedByLastContactDate = Array.from(items.sort(
      (prev: Convs, curr: Convs) => {
        const prevLastContactDateValue = prev?.table.find(
          (item: Table) => item.key === lastContactHeader.key,
        )?.value;
        const currLastContactDateValue = curr?.table.find(
          (item: Table) => item.key === lastContactHeader.key,
        )?.value;

        if (sort.toUpperCase() === 'DESC') {
          setSortByLastContactDateOrder('ASC');
          return moment(
            currLastContactDateValue,
          ).diff(moment(prevLastContactDateValue));
        }
        setSortByLastContactDateOrder('DESC');
        return moment(
          prevLastContactDateValue,
        ).diff(moment(currLastContactDateValue));
      },
    ));

    setItems(chatsOrderedByLastContactDate);
  };

  /** sort by name (aka visitor name) */
  const sortByVisitorName = (sort: string) => sortByName({
    key: nameHeader.key,
    order: sort ? sort.toUpperCase() : sortByVisitorNameOrder,
    setOrder: setSortByVisitorNameOrder,
  });

  /** sort by `chattingWith` (aka operator name) */
  const sortByOperatorName = (sort: string) => sortByName({
    key: chattingWithHeader.key,
    order: sort ? sort.toUpperCase() : sortByOperatorNameOrder,
    setOrder: setSortByOperatorNameOrder,
  });

  const sortByContition = (sort: string = sortByContitionOrder) => {
    const chatsOrderedByLastContactDate = Array.from(items.sort(
      (prev: Convs, curr: Convs) => {
        const prevCondition = prev?.table[
          conditionHeader.index
        ]?.value?.toLowerCase();
        const CurrCondition = curr?.table[
          conditionHeader.index
        ]?.value?.toLowerCase();
        if (sort.toLocaleUpperCase() === 'DESC') {
          setsortByContitionOrder('ASC');
          return prevCondition > CurrCondition ? 1 : -1;
        }
        setsortByContitionOrder('DESC');
        return CurrCondition > prevCondition ? 1 : -1;
      },
    ));
    setItems(chatsOrderedByLastContactDate);
  };

  return ({
    nameHeader,
    channelHeader,
    statusHeader,
    lastContactHeader,
    chattingWithHeader,
    actionsHeader,
    conditionHeader,
    intervenedBy,
    tags,
    sortByChannel,
    sortByStatus,
    sortByLastContactDate,
    sortByVisitorName,
    sortByOperatorName,
    sortByContition,
  });
};

export default useSortingItems;
