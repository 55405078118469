import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';

import analytics, { EVENTS } from '../../../services/analytics';
import { isEmpty } from '../../../utils';
import { translate } from '../../../services/translate';
import { socketActions } from '../../../services/socketeerConnections';
import { uploadFile } from '../../../services/cloudinary';
import ChatInput from '../../ChatInput/ChatInput';
import ChatInterveneButton from '../../ChatInterveneButton/ChatInterveneButton';
import WarningMessage from '../../WarningMessage/WarningMessage';

import {
  Footer,
  FormContainer,
} from './styles';

function createMessage({
  conversationId,
  messageBody,
}) {
  const msg = {
    action: socketActions.SEND_MESSAGE,
    body: messageBody.text,
    message: messageBody,
    conversationId,
  };

  return msg;
}

const ChatFooter = ({
  placeholderText,
  callbackChatBox,
  whatsappPremium,
  isLoadingMessages,
  conversationId,
  currentEmoji,
  setCurrentEmoji,
  isOpenEmoji,
  setIsOpenEmoji,
  refInput,
  finishedConversation,
  chatbotName,
  tab,
  socketFunction,
  isExternalOperator,
  websiteName,
}) => {
  const { featureLiveWhatsappmultiattachLive260, featureLiveWarnignuserdisconnectedLive257 } = useFlags();
  const [text, setText] = useState('');
  const trackSendText = () => {
    const event = {
      name: EVENTS.SEND_TEXT,
      category: 'live',
    };
    analytics.trackEvent(event);
  };
  const sendMessage = () => {
    if (!isEmpty(text)) {
      const messageBody = { text, type: 'text/plain' };
      const message = createMessage({ conversationId, messageBody });

      trackSendText();
      callbackChatBox(message);
    }
    setText('');
  };
  const onKeyPress = (ev) => {
    if (ev.keyCode === 13) {
      sendMessage();
    }
  };

  const onSingleAttachment = async (fileData, caption) => {
    const hasCaption = ['video', 'image'].includes(fileData.type);
    const bodyMessage = {
      type: fileData.type,
      body: '',
      message: {
        type: fileData.type,
        url: '',
        caption: hasCaption ? caption : '',
        size: '',
        duration: '',
      },
    };
    const data = {
      file: fileData.url,
      type: fileData.type,
    };

    // si el tiṕo de archivo es otro que no sea video audio imagen o pdf,
    // se incluye el puclic id para poder almacenarlo con la extension del archivo, ya que de otro modo no lo almacena
    if (fileData.mime !== 'pdf') {
      data.public_id = fileData.name;
    }

    const uploadResponse = await uploadFile(data);

    if (uploadResponse.url !== '') {
      bodyMessage.message.url = uploadResponse.url;
      bodyMessage.message.size = uploadResponse.size;

      if (uploadResponse.duration) {
        bodyMessage.message.duration = uploadResponse.duration;
      }

      callSinglebackAttachment(bodyMessage);
    }
  };

  const callSinglebackAttachment = async (bodyMessage) => {
    const msg = createMessage({ conversationId, messageBody: bodyMessage.message });

    callbackChatBox(msg);
    setText('');
  };

  const trackAttachSingleFileEvent = (label) => analytics.trackEvent({ name: EVENTS.ATTACH_FILE, label });

  const onAttachment = async (files, caption) => {
    const arrayMessages = [];
    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      const data = {
        file: fileData.url,
        type: fileData.type,
      };

      // si el tiṕo de archivo es otro que no sea video audio imagen o pdf,
      // se incluye el puclic id para poder almacenarlo con la extension del archivo, ya que de otro modo no lo almacena
      if (fileData.mime !== 'pdf') {
        data.public_id = fileData.name;
      }

      const uploadResponse = await uploadFile(data); // eslint-disable-line no-await-in-loop

      if (uploadResponse.url !== '') {
        const hasCaption = ['video', 'image'].includes(fileData.type);
        const bodyMessage = {
          type: fileData.type,
          body: '',
          message: {
            type: fileData.type,
            url: uploadResponse.url,
            caption: hasCaption ? fileData.caption : '',
            size: uploadResponse.size,
            duration: '',
            extension: fileData.extension,
          },
        };
        if (uploadResponse.duration) {
          bodyMessage.message.duration = uploadResponse.duration;
        }
        arrayMessages.push(bodyMessage);
      }
    }

    callbackAttachment({ arrayMessages, caption });
  };

  const callbackAttachment = async ({ arrayMessages, caption }) => {
    const msgs = [];

    for (let i = 0; i < arrayMessages.length; i++) {
      const bodyMessage = arrayMessages[i];
      const msg = createMessage({
        conversationId,
        messageBody: bodyMessage.message,
      });

      msgs.push(msg);
    }

    if (caption) {
      const captionMessage = createMessage({ conversationId, messageBody: caption });
      msgs.unshift(captionMessage);
    }

    callbackChatBox(msgs);
    setText('');
  };

  const translationsForSendFile = {
    errorTypeNotAllowed: translate('my_chats_section.chat_multimedia.errors.type_not_allowed'),
    errorMaxFileLength: translate('my_chats_section.chat_multimedia.errors.max_file_length'),
    errorGeneralError: translate('my_chats_section.chat_multimedia.errors.general_error'),
    typeExtensionsTitle: translate('my_chats_section.chat_multimedia.type_extensions_title'),
    typeExtensionsUrls: translate('my_chats_section.chat_multimedia.type_extensions'),
    writeMessage: translate('my_chats_section.chat_multimedia.write_message'),
  };

  const notAllowedTypesToSendFile = [
    'action', 'apk', 'app', 'bat', 'bin', 'cmd', 'com', 'command', 'cpl', 'csh', 'exe', 'gadget',
    'inf1', 'ins', 'inx', 'ipa', 'isu', 'job', 'jse', 'ksh', 'lnk', 'msc', 'msi', 'msp', 'mst',
    'osx', 'out', 'paf', 'pif', 'prg', 'ps1', 'reg', 'rgs', 'run', 'sct', 'shb', 'shs', 'u3p', 'vb',
    'vbe', 'vbs', 'vbscript', 'workflow', 'ws', 'wsf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'gif',
    'GIF',
  ];

  useEffect(() => {
    if (refInput?.current) {
      const inp = refInput.current;
      if (isLoadingMessages) {
        inp.disabled = true;
      } else {
        inp.disabled = false;
        inp.focus();
      }
    }
  }, [isLoadingMessages]);

  useEffect(() => {
    if (currentEmoji) {
      setText(`${text} ${currentEmoji}`);
      setCurrentEmoji(null);
    }
  }, [currentEmoji]);

  const clickEmojiAction = () => {
    setIsOpenEmoji(!isOpenEmoji);
  };

  return (
    <Footer id="chatFooter" isAttachment={whatsappPremium}>
      <FormContainer>
        {
          featureLiveWarnignuserdisconnectedLive257 // check the flag
          && (
            <WarningMessage
              user={chatbotName}
              isFinishedConversation={finishedConversation}
              isExternalOperato={isExternalOperator}
              websiteName={websiteName}
            />
          )
        }
        {tab !== 'mine'
          && (
          <ChatInterveneButton
            isLoadingMessages={isLoadingMessages}
            socketFunction={socketFunction}
          />
          )}
        {tab === 'mine'
          && (
          <ChatInput
            text={text}
            setText={setText}
            placeholderText={placeholderText}
            onKeyPress={onKeyPress}
            whatsappPremium={whatsappPremium}
            onAttachment={featureLiveWhatsappmultiattachLive260 ? onAttachment : onSingleAttachment}
            translationsForSendFile={translationsForSendFile}
            notAllowedTypesToSendFile={notAllowedTypesToSendFile}
            trackAttachFileEvent={trackAttachSingleFileEvent}
            isOpenEmoji={isOpenEmoji}
            clickEmojiAction={clickEmojiAction}
            sendMessage={sendMessage}
            tab={tab}
          />
          )}

      </FormContainer>
    </Footer>
  );
};

ChatFooter.propTypes = {
  placeholderText: PropTypes.string.isRequired,
  conversationId: PropTypes.string,
  whatsappPremium: PropTypes.bool,
};
ChatFooter.defaultProps = {
  whatsappPremium: false,
};

export default ChatFooter;
