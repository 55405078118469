export const getLocalStorage = (key: string):any | false => {
  try {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
  } catch (error) {
    console.info(error);
    return false;
  }
};

export default getLocalStorage;
